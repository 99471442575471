import React from 'react';
import cn from 'classnames';

import { SportType } from 'app-constants';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { useBettingLayout } from 'layouts/BettingLayout/BettingLayoutContext';
import { IconData } from 'types';
import Image from '../Image';

interface Props extends Omit<IconData, 'icon'> {
    sportId: string;
    isActive?: boolean;
}

const orangeFilterImage =
    'invert(50%) sepia(100%) saturate(4300%) hue-rotate(360deg) brightness(110%) contrast(140%)';

const SportIcon: React.FC<Props> = ({ sportId, className, isActive, size }) => {
    const { sports } = useBettingLayout();

    const { icon, sportType } = sports[sportId] || {};
    const hasIconKey = sports[sportId]?.hasOwnProperty('icon');

    const defaultIcon =
        sportType === SportType.Esports
            ? 'cybersport_default'
            : 'classic_default';

    if (!hasIconKey) {
        return null;
    }

    if (!icon) {
        return (
            <PackIcon
                id={defaultIcon}
                pack={IconsPack.SpriteIcons}
                size={size}
                className={cn(
                    className,
                    isActive ? 'fill-primary-orange-toxic' : 'fill-grey-500'
                )}
            />
        );
    }

    // TODO: Check url from admin settings

    return (
        <span className="relative inline-flex h-6 w-6 shrink-0">
            <Image
                width={24}
                height={24}
                alt=""
                className={className}
                unoptimized={true}
                style={
                    isActive
                        ? {
                              filter: orangeFilterImage,
                          }
                        : undefined
                }
                src={`/file/${icon}`}
            />
        </span>
    );
};

export default SportIcon;
