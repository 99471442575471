import { FC, memo } from 'react';
import { includes } from 'lodash';
import { useTranslations } from 'next-intl';

import { SPORTS_WITHOUT_TOTAL_SCORE } from 'components/betting/Match/constants';
import {
    ScoreBoardColors,
    ScoreBoardScore,
    ScoreType,
} from 'components/betting/Match/ScoreBoard/types';
import OverviewScoresItem from './OverviewScoresItem';

interface Props {
    totalScore: ScoreBoardScore;
    scores?: ScoreBoardScore[];
    colors?: ScoreBoardColors;
    sportId: string;
}

const OverviewScores: FC<Props> = ({
    scores,
    totalScore,
    colors = { home: '', away: '' },
    sportId,
}) => {
    const t = useTranslations('betting-specifiers.specifiers');

    const totalOverviewScore = !includes(
        SPORTS_WITHOUT_TOTAL_SCORE,
        sportId
    ) ? (
        <OverviewScoresItem
            isTotal={true}
            header={t('score')}
            homeScore={totalScore.home}
            awayScore={totalScore.away}
        />
    ) : null;

    if (!scores?.length) {
        return totalOverviewScore;
    }

    return (
        <div className="flex max-w-full gap-2">
            {scores.map((score, index) => {
                const isActive = score.type === ScoreType.CurrentPart;

                return (
                    <OverviewScoresItem
                        key={index}
                        homeScore={score.home}
                        awayScore={score.away}
                        header={score.title}
                        isActivePart={isActive}
                        homeClassName={isActive ? colors.home : ''}
                        awayClassName={isActive ? colors.away : ''}
                    />
                );
            })}

            {totalOverviewScore}
        </div>
    );
};

export default memo(OverviewScores);
