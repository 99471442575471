import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import {
    Competitor,
    CompetitorScore,
    EFootballPeriod,
    FootballPeriod,
    FutsalPeriod,
} from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import {
    AdaptFixtureScoreOptions,
    Meta,
    ScoreBoardScore,
    ScoreType,
    TranslateScoreboard,
} from '../types';

export function adaptFixtureScores(
    competitors: Competitor[],
    options: AdaptFixtureScoreOptions = {}
): ScoreBoardScore[] {
    if (competitors.length < 2) {
        console.warn('Missing competitor');

        return [];
    }

    const { scoreIteratee, activeScoreType } = options;

    const [home, away] = competitors;

    const homeScoresWithoutTotal =
        home.score?.filter((score) => score.type !== ScoreTypes.TYPE_TOTAL) ||
        [];

    const isZeroBased = !!homeScoresWithoutTotal?.find((s) => s.number === 0);

    return homeScoresWithoutTotal
        .reduce<ScoreBoardScore[]>((acc, score) => {
            if (typeof scoreIteratee === 'function') {
                const res = scoreIteratee(score, isZeroBased);

                // Filter score when "false" is returned
                if (res === false) {
                    return acc;
                }

                if (res) {
                    acc.push(res);

                    return acc;
                }
            }

            // NOTE: Score number starts from zero in some sports
            const title = `${isZeroBased ? score.number + 1 : score.number}`;

            const type =
                activeScoreType &&
                score.type === activeScoreType.type &&
                score.number === activeScoreType.number
                    ? ScoreType.CurrentPart
                    : ScoreType.Other;

            acc.push({
                title,
                type,
                home: score.points,
                away: findAwayScore(away, score.id).points,
            });

            return acc;
        }, [])
        .sort((a, b) => +a.title - +b.title);
}

export function findAwayScore(
    away: Competitor,
    scoreId: string
): CompetitorScore {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return away.score.find(({ id }) => id === scoreId)!;
}

export function makeMetaFinder(
    name: SportEventMetaKey,
    number: SportEventMetaKey
) {
    return (meta: Meta[]): Meta | undefined => {
        const metaType = getMetaValueByName(meta, name);
        const metaTypeValue = getMetaValueByName(meta, number);

        if (!metaType || !metaTypeValue) return;

        return {
            name: metaType,
            value: metaTypeValue,
        };
    };
}

export const getMessage = (
    currentMatchPart:
        | FootballPeriod
        | EFootballPeriod
        | FutsalPeriod
        | ScoreTypes,
    penaltiesScore: string,
    translate: TranslateScoreboard,
    pause?: boolean
): string => {
    const { FirstHalf, SecondHalf, FirstExtra, SecondExtra, Penalties } =
        FootballPeriod;
    const {
        FirstHalf: eFirstHalf,
        SecondHalf: eSecondHalf,
        FirstExtra: eFirstExtra,
        SecondExtra: eSecondExtra,
        Penalties: ePenalties,
    } = EFootballPeriod;
    const {
        FirstHalf: firstHalfFutsal,
        SecondHalf: secondHalfFutsal,
        FirstExtra: firstExtraFutsal,
        SecondExtra: secondExtraFutsal,
        Penalties: penaltiesFutsal,
    } = FutsalPeriod;
    const {
        TYPE_PERIOD_1ST_HALF,
        TYPE_PERIOD_2ND_HALF,
        TYPE_PERIOD_1ST_EXTRA,
        TYPE_PERIOD_2ND_EXTRA,
        TYPE_PERIOD_PENALTIES,
    } = ScoreTypes;

    const { tSpecifier } = translate;

    if (pause) {
        return tSpecifier('timeout');
    }

    switch (true) {
        case [
            FirstHalf,
            eFirstHalf,
            TYPE_PERIOD_1ST_HALF,
            firstHalfFutsal,
        ].includes(currentMatchPart):
            return `1 ${tSpecifier('time')}`;
        case [
            SecondHalf,
            eSecondHalf,
            TYPE_PERIOD_2ND_HALF,
            secondHalfFutsal,
        ].includes(currentMatchPart):
            return `2 ${tSpecifier('time')}`;
        case [
            FirstExtra,
            eFirstExtra,
            TYPE_PERIOD_1ST_EXTRA,
            firstExtraFutsal,
        ].includes(currentMatchPart):
            return `1 ${tSpecifier('overtimenr')}`;
        case [
            SecondExtra,
            eSecondExtra,
            TYPE_PERIOD_2ND_EXTRA,
            secondExtraFutsal,
        ].includes(currentMatchPart):
            return `2 ${tSpecifier('overtimenr')}`;
        case [
            Penalties,
            ePenalties,
            TYPE_PERIOD_PENALTIES,
            penaltiesFutsal,
        ].includes(currentMatchPart):
            return `${tSpecifier('penalty')} (${penaltiesScore})`;
        default:
            return '';
    }
};

export function isZeroBasedFixture(
    scores: CompetitorScore[],
    type: ScoreTypes
): boolean {
    return !!scores.find(
        (scoreItem) => scoreItem.type === type && scoreItem.number === 0
    );
}
