import { map } from 'lodash';

import type { MortalKombat } from 'types/gql.bet';
import { MortalKombatWinReason, TeamHomeAway } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreBoardScore, ScoreType } from '../types';

const mortalKombatAdapter: ScoreboardAdapter<MortalKombat> = {
    adaptOverviewScores(overview, { isMatchListView }) {
        const { fights, currentFight } = overview;
        const scores: ScoreBoardScore[] = map(
            fights,
            ({ winner, home, number, away, winReason }) => {
                return {
                    title: `${number}`,
                    home: getScore({
                        side: TeamHomeAway.Home,
                        winner,
                        winReason,
                        score: home.score,
                        isMatchListView,
                    }),
                    away: getScore({
                        side: TeamHomeAway.Away,
                        winner,
                        winReason,
                        score: away.score,
                        isMatchListView,
                    }),
                    type:
                        number === currentFight
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                };
            }
        );

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { timer, bestOf, currentRound } = overview;

        const title = `${bestOf ? `BO${bestOf} ` : ''} ${tSpecifier(
            'roundnr'
        )} ${currentRound}`;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.Total,
            timer: {
                time: timer.time,
                isTimerRunning: timer.isActive,
                isDecrement: false,
            },
        };
    },
};

export interface GetScoreInput {
    side: TeamHomeAway;
    winner: TeamHomeAway;
    winReason?: MortalKombatWinReason;
    score: number;
    isMatchListView: boolean;
}
export function getScore({
    side,
    winner,
    winReason,
    score,
    isMatchListView,
}: GetScoreInput): string {
    if (winner === TeamHomeAway.Unknown) {
        return `${score}`;
    }

    const winnerScore =
        winReason && !isMatchListView
            ? `W (${
                  winReason.charAt(0).toUpperCase() +
                  winReason.slice(1).toLowerCase()
              })`
            : 'W';

    return side === winner ? winnerScore : 'L';
}

export default mortalKombatAdapter;
