import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import type { Overview, PartialMatchMarket, PartialMatchMarkets } from 'types';

import { SpecifierName } from 'components/betting/BetsHistory/BetItem/Odd/constants';
import { Sports } from 'components/betting/Categorizer/constants';
import { adaptSport } from 'components/betting/Match/ScoreBoard/adapter';
import {
    Competitor as CompetitorType,
    MatchBaseWithOptionalMeta,
} from 'types/gql.bet';
import LiveFixture from '../SportEventRowMainInfo/LiveFixture';
import PrematchFixture from '../SportEventRowMainInfo/PrematchFixture';
import MultiCompetitorsMarket from './MultiCompetitorsMarket';

interface Props {
    sportEventId: string;
    matchDay?: string;
    matchTime?: string;
    isAvailableStream: boolean;
    isResizable: boolean;
    isPrematchView: boolean;
    overview?: Overview;
    sportId: string;
    competitors: CompetitorType[];
    meta: MatchBaseWithOptionalMeta['meta'];
    markets: PartialMatchMarkets;
}

const MultiCompetitorsOverviewRow: FC<Props> = ({
    sportEventId,
    matchDay,
    matchTime,
    isAvailableStream,
    isResizable,
    isPrematchView,
    overview,
    sportId,
    meta,
    competitors,
    markets,
}) => {
    const tSpecifier = useTranslations('betting-specifiers.specifiers');

    const { matchInfo, timer } = adaptSport({
        overview,
        sportId,
        competitors,
        translate: {
            tSpecifier,
        },
        meta,
        isMatchListView: true,
    });

    const market = getFirstWinnerMarket(markets, sportId);

    if (!market) return null;

    return (
        <div
            className={cn('bg-surface-middle', {
                ['mb-2 sm:flex-row sm:gap-x-6']: isResizable,
            })}
        >
            <div className="flex h-12 items-center gap-2 border-b-default border-surface-dark px-4">
                {isPrematchView ? (
                    <PrematchFixture
                        sportEventId={sportEventId}
                        isAvailableStream={isAvailableStream}
                        time={matchTime}
                        day={matchDay}
                    />
                ) : (
                    <LiveFixture
                        matchInfo={matchInfo}
                        isAvailableStream={isAvailableStream}
                        timer={timer}
                    />
                )}
            </div>
            <MultiCompetitorsMarket
                sportEventId={sportEventId}
                market={market}
                isResizable={isResizable}
            />
        </div>
    );
};

const findMapnrSpecifierValue = (market: PartialMatchMarket): number => {
    if (!market.specifiers?.length) return 0;

    const specifierValue =
        market.specifiers.find((s) => s.name === SpecifierName.mapnr)?.value ||
        0;

    return +specifierValue;
};

const getFirstWinnerMarket = (
    markets: PartialMatchMarkets,
    sportId: string
): PartialMatchMarket => {
    // TODO make some mapping for different sports and different specifiers when will have more info about multicompetitors matches
    if (sportId === Sports.ESPORTS_BATTLEGROUNDS) {
        return markets
            .filter(({ specifiers }) =>
                (specifiers || []).some((s) => s.name === SpecifierName.mapnr)
            )
            .sort(
                (a, b) =>
                    findMapnrSpecifierValue(a) - findMapnrSpecifierValue(b)
            )[0];
    }

    return markets[0];
};

export default MultiCompetitorsOverviewRow;
