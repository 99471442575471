import { last } from 'lodash';

import { ScoreTypes } from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';

const { TYPE_GAME_KILLS, TYPE_GAME_PLAYERS } = ScoreTypes;

const battleRoyalAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ competitors }, { translate: { tSpecifier } }) {
        const currentGame =
            last(
                competitors[0].score.filter(
                    (score) => score.type === TYPE_GAME_KILLS
                )
            )?.number || 1;

        return {
            mainScoreType: ScoreType.Total,
            matchInfo: {
                title: `${tSpecifier('gamenr')} ${currentGame}`,
            },
        };
    },
    adaptFixtureScores({ meta }) {
        const gameKills = getMetaValueByName(meta, TYPE_GAME_KILLS) || '-';
        const leftPlayers = getMetaValueByName(meta, TYPE_GAME_PLAYERS) || '-';

        return [
            {
                home: gameKills,
                away: '',
                title: 'Kills',
                type: ScoreType.CurrentPart,
            },
            {
                home: leftPlayers,
                away: '',
                title: 'Left',
                type: ScoreType.CurrentPart,
            },
        ];
    },
};

export default battleRoyalAdapter;
