import {
    MouseEventHandler,
    RefObject,
    useCallback,
    useRef,
    useState,
    WheelEvent,
} from 'react';

import { useMount } from 'hooks';

const SCROLL_LOOP_STEP = 20; // pixels
const SCROLL_LOOP_DELAY = 50; // ms
const SCROLL_LOOP_TIMEOUT = 600; // ms

interface Input {
    sideSpacing?: number;
}

interface Output {
    containerRef: RefObject<HTMLDivElement>;
    showLeftArrow: boolean;
    showRightArrow: boolean;
    startScroll: MouseEventHandler<HTMLDivElement>;
    stopScroll: MouseEventHandler<HTMLDivElement>;
    updateArrowsVisibility: VoidFunction;
}

const useHorizontalScroll = ({ sideSpacing }: Input): Output => {
    const scrollTimer = useRef<NodeJS.Timeout | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

    useMount(() => {
        updateArrowsVisibility();

        const handleWheel: EventListener = (event) => {
            const typedEvent = event as unknown as WheelEvent<HTMLDivElement>;

            event.preventDefault();

            typedEvent.currentTarget.scrollBy({
                left: typedEvent.deltaY,
            });
        };

        containerRef.current?.addEventListener('wheel', handleWheel);

        return () => {
            containerRef.current?.removeEventListener('wheel', handleWheel);

            if (scrollTimer.current) clearTimeout(scrollTimer.current);
        };
    });

    const handleScroll = useCallback((offset: number) => {
        if (!containerRef.current) return;

        containerRef.current.scrollLeft += offset;
    }, []);

    const loopScroll = useCallback(
        (offset: number) => {
            handleScroll(offset);

            if (
                scrollTimer.current &&
                containerRef.current &&
                ((offset > 0 &&
                    containerRef.current.scrollLeft +
                        containerRef.current.clientWidth >=
                        containerRef.current.scrollWidth) ||
                    (offset <= 0 && containerRef.current.scrollLeft <= 0))
            ) {
                clearTimeout(scrollTimer.current);

                return;
            }

            scrollTimer.current = setTimeout(() => {
                loopScroll(offset);
            }, SCROLL_LOOP_DELAY);
        },
        [handleScroll]
    );

    const startScroll: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const { arrow } = event.currentTarget.dataset;
            let offset = 0;

            if (event.button !== 0 || !arrow) return;

            if (arrow === 'left') offset -= SCROLL_LOOP_STEP;

            if (arrow === 'right') offset += SCROLL_LOOP_STEP;

            handleScroll(offset);

            scrollTimer.current = setTimeout(() => {
                loopScroll(offset);
            }, SCROLL_LOOP_TIMEOUT);
        },
        [handleScroll, loopScroll]
    );

    const stopScroll: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const { arrow } = event.currentTarget.dataset;

            if (event.button !== 0 || !arrow || !scrollTimer.current) return;

            clearTimeout(scrollTimer.current);
        },
        []
    );

    const updateArrowsVisibility = useCallback(() => {
        const element = containerRef.current;

        if (!element) return;

        setShowLeftArrow(
            sideSpacing
                ? element.scrollLeft > sideSpacing
                : element.scrollLeft > 0
        );
        setShowRightArrow(
            sideSpacing
                ? element.scrollLeft + element.clientWidth <
                      element.scrollWidth - sideSpacing
                : element.scrollLeft + element.clientWidth < element.scrollWidth
        );
    }, [sideSpacing]);

    return {
        containerRef,
        showLeftArrow,
        showRightArrow,
        startScroll,
        stopScroll,
        updateArrowsVisibility,
    };
};

export default useHorizontalScroll;
