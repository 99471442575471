import { FC, memo } from 'react';
import cn from 'classnames';

import Competitor from 'components/betting/Competitor';
import OpacityWrapper from 'components/OpacityWrapper';
import { Action, useAnalyticAttrs } from 'services/GoogleAnalytics';
import { Competitor as CompetitorType } from 'types/gql.bet';
import { getHomeAwayCompetitors } from 'utils';
import PrematchFixture from '../PrematchFixture';

interface Props {
    competitors: CompetitorType[];
    day?: string;
    time?: string;
    isAvailableStream: boolean;
    isResizable?: boolean;
    sportEventId: string;
}

const PrematchMainInfo: FC<Props> = ({
    competitors,
    day,
    time,
    isAvailableStream,
    isResizable,
    sportEventId,
}) => {
    const analytic = useAnalyticAttrs({
        defaultAttrs: {
            'data-action': Action.ClickOnTitle,
        },
    });

    const [homeCompetitor, awayCompetitor] =
        getHomeAwayCompetitors(competitors);

    const competitorClasses = cn('gap-3', { 'sm:gap-0': isResizable });

    return (
        <div className="flex w-0 grow flex-col items-start gap-4 bg-surface-middle">
            <div className="flex h-12 items-center gap-4">
                <div className="flex h-12 items-center gap-2">
                    <PrematchFixture
                        sportEventId={sportEventId}
                        isAvailableStream={isAvailableStream}
                        time={time}
                        day={day}
                    />
                </div>
            </div>

            <div
                className={cn(
                    'flex h-full w-full items-center self-center bg-surface-middle ',
                    {
                        'sm:self-start': isResizable,
                    }
                )}
                data-test="prematch-main-info__game"
            >
                <div
                    className={cn(
                        'mb-2 mt-1 flex w-full justify-between gap-4',
                        {
                            'sm:mt-0 sm:flex-col': isResizable,
                        }
                    )}
                    data-category={analytic['data-category']}
                    data-action={analytic['data-action']}
                >
                    <Competitor
                        type={homeCompetitor.type}
                        logo={homeCompetitor.logo}
                        name={homeCompetitor.name}
                        className={competitorClasses}
                        displayType={!isResizable ? 'column' : undefined}
                    />

                    <OpacityWrapper>
                        {(opacityClassName) => (
                            <div
                                className={cn(
                                    'flex w-full flex-col items-center justify-between text-primary-white',
                                    { 'sm:hidden': isResizable },
                                    opacityClassName
                                )}
                            >
                                <div className="flex flex-1 items-center text-md">
                                    {time}
                                </div>
                                <div className="text-sm ">{day}</div>
                            </div>
                        )}
                    </OpacityWrapper>
                    <Competitor
                        logo={awayCompetitor.logo}
                        name={awayCompetitor.name}
                        className={competitorClasses}
                        type={awayCompetitor.type}
                        displayType={!isResizable ? 'column' : undefined}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(PrematchMainInfo);
