import { SportEventMetaKey } from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';
import { adaptFixtureScores } from './utils';

const { Round, Bo, Map } = SportEventMetaKey;

const valorantAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta }, { translate: { tSpecifier } }) {
        const currentMap = getMetaValueByName(meta, Map);
        const currentRound = getMetaValueByName(meta, Round);
        const bo = getMetaValueByName(meta, Bo);

        return {
            matchInfo: {
                title: currentMap ? `${tSpecifier('map')} ${currentMap}` : '',
                subTitle: currentRound ? `R: ${currentRound}` : '',
            },
            mainScoreType: ScoreType.Total,
            bo: bo ? Number(bo) : undefined,
        };
    },
    adaptFixtureScores({ competitors }, { isMatchListView }) {
        const scores = adaptFixtureScores(competitors);

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
};

export default valorantAdapter;
