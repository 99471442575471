import React from 'react';
import Head from 'next/head';
import type { PartialMatch } from 'types';

import withFeatureFlag from 'hocs/withFeatureFlag';
import { Feature } from 'services/features';
import useSportEventSeoMicromarking from './useSportEventSeoMicromarking';

interface Props {
    fixture: PartialMatch['fixture'];
}

const SportEventSeoMicromarking: React.FC<Props> = ({ fixture }) => {
    const { seoMicromarking } = useSportEventSeoMicromarking({
        fixture,
    });

    if (!seoMicromarking) return null;

    return (
        <Head key={fixture.tournament.id}>
            <script
                type="application/ld+json"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(seoMicromarking),
                }}
            />
        </Head>
    );
};

export default withFeatureFlag(
    SportEventSeoMicromarking,
    Feature.AppEnableMicromarking
);
