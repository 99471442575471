import React from 'react';

import { Overview } from 'types';
import { Competitor, MatchBaseWithOptionalMeta } from 'types/gql.bet';
import LiveMainInfo from './LiveMainInfo';
import PrematchMainInfo from './PrematchMainInfo';

interface Props {
    sportId: string;
    competitors: Competitor[];
    meta: MatchBaseWithOptionalMeta['meta'];
    isPrematchView: boolean;
    isAvailableStream: boolean;
    overview?: Overview;
    matchDay: string;
    matchTime: string;
    isResizable?: boolean;
    sportEventId: string;
}

const SportEventRowMainInfo: React.FC<Props> = ({
    sportId,
    isPrematchView,
    isAvailableStream,
    competitors,
    overview,
    matchDay,
    matchTime,
    meta,
    isResizable,
    sportEventId,
}) => {
    return (
        <div className="flex h-full w-full">
            {isPrematchView ? (
                <PrematchMainInfo
                    day={matchDay}
                    time={matchTime}
                    competitors={competitors}
                    isAvailableStream={isAvailableStream}
                    isResizable={isResizable}
                    sportEventId={sportEventId}
                />
            ) : (
                <LiveMainInfo
                    competitors={competitors}
                    isAvailableStream={isAvailableStream}
                    overview={overview}
                    sportId={sportId}
                    meta={meta}
                />
            )}
        </div>
    );
};

export default React.memo(SportEventRowMainInfo);
