import { fillLink } from 'cl-fill-link';
import { useTranslations } from 'next-intl';
import type { PartialMatch } from 'types';

import { AppLink, SportType } from 'app-constants';
import { HeaderType } from 'components/betting/SportEventRow/constants';
import { useBroadcastHelper } from 'components/betting/Stream';
import { useRouter } from 'components/HybridRouter';
import { useDateText, useFormat } from 'hooks';
import { Stream } from 'types/api.bet';
import { SportEventStatus } from 'types/gql.bet';
import { getSportTypeBySportTag } from 'utils';

const resultsAppLinks = [AppLink.RESULTS, AppLink.FAVORITE_RESULTS];

interface Input {
    fixture: PartialMatch['fixture'];
    streams?: Stream[];
    matchSlug: string;
    meta: PartialMatch['meta'];
    headerType: HeaderType;
}

interface Output {
    isPrematchView: boolean;
    matchRoute: string;
    matchDay: string;
    matchTime: string;
    isAvailableStream: boolean;
    headerLinkHref?: string;
    headerName?: string;
}

const useSportEventRow = ({
    fixture,
    streams = [],
    matchSlug: slug,
    meta,
    headerType,
}: Input): Output => {
    const { tournament, sport, startTime, status = [] } = fixture;

    const translate = useTranslations('betting-global.global');
    const { pathname } = useRouter();
    const { format } = useFormat();

    // TODO: Check grid stream availability for "Counter-Strike"
    const { isAvailableMatchTracker, isStreamEnable } = useBroadcastHelper({
        streams,
        meta,
    });

    const isAvailableStream = isStreamEnable || isAvailableMatchTracker;

    const isPrematchView =
        status !== SportEventStatus.Live &&
        status !== SportEventStatus.Suspended;

    const sportType = getSportTypeBySportTag(sport.tags[0]);

    const isEsport = sportType === SportType.Esports;

    const tournamentLinkBySportType = isEsport
        ? AppLink.ESPORTS_TOURNAMENT_SLUG
        : AppLink.SPORTS_TOURNAMENT_SLUG;

    const resultLinkBySportType = isEsport
        ? AppLink.ESPORTS_RESULT_SLUG
        : AppLink.SPORTS_RESULT_SLUG;

    const matchLinkBySportType = isEsport
        ? AppLink.ESPORTS_MATCH_SLUG
        : AppLink.SPORTS_MATCH_SLUG;

    const date = new Date(startTime);

    const matchDay = useDateText({
        startTime: date,
        translate,
    });

    const matchTime = format(date, 'HH:mm');

    const tournamentRoute = fillLink(tournamentLinkBySportType, {
        slug: tournament.slug,
    });

    const isResult = resultsAppLinks.includes(pathname as AppLink);

    const matchRoute = fillLink(
        isResult ? resultLinkBySportType : matchLinkBySportType,
        {
            slug,
        }
    );

    let headerName;
    let headerLinkHref;

    if (headerType === HeaderType.Tournament) {
        headerName = fixture?.tournament.name;

        if (!isResult) {
            headerLinkHref = tournamentRoute;
        }
    } else if (headerType === HeaderType.Sport) {
        headerName = fixture?.sport.name;
    }

    return {
        isPrematchView,
        matchRoute,
        matchDay,
        matchTime,
        isAvailableStream,
        headerName,
        headerLinkHref,
    };
};

export default useSportEventRow;
