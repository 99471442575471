import { max } from 'lodash';

import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import { IceHockeyOverviewPeriodTeam } from 'types/api.bet';
import { IceHockey, IceHockeyPeriodType } from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import {
    FixtureScoreboardAdapter,
    ScoreboardAdapter,
    ScoreBoardScore,
    ScoreType,
    TranslateScoreboard,
} from '../types';
import { findAwayScore, isZeroBasedFixture } from './utils';

interface GetTitleInput {
    currentPeriodNumber: number;
    pause?: boolean;
    isOvertime: boolean;
    isShootout: boolean;
    translate: TranslateScoreboard;
    isZeroBased?: boolean;
}

const MAX_BULLET_SIZE = 4 as const;

const { Overtime, Bullets, Regular } = IceHockeyPeriodType;

const { TimerRunning, Period, PeriodNumber, Time } = SportEventMetaKey;

const {
    TYPE_PERIOD_BULLETS,
    TYPE_PERIOD_OVERTIME,
    TYPE_PERIOD_REGULAR,
    TYPE_BULLETS,
    TYPE_OVERTIME,
} = ScoreTypes;

const { Total, CurrentPart, Other, ExtraInfo } = ScoreType;

const iceHockeyAdapter: ScoreboardAdapter<IceHockey> &
    FixtureScoreboardAdapter = {
    adaptOverviewInfo(overview, { translate }) {
        const {
            currentPeriod: currentPeriodNumber,
            timer,
            pause,
            isOvertime,
            isShootout,
        } = overview;

        return {
            timer: !isShootout
                ? {
                      time: timer.time,
                      isTimerRunning: timer.isActive && !pause,
                      isDecrement: true,
                  }
                : undefined,
            matchInfo: {
                title: getTitleInfo({
                    currentPeriodNumber,
                    pause,
                    isOvertime,
                    isShootout,
                    translate,
                }),
            },
            mainScoreType: Total,
        };
    },
    adaptOverviewScores(overview, { isMatchListView }) {
        const { currentPeriod, periods, isShootout } = overview;

        if (!periods) return [];

        let overTimeIdx = 1;
        let bulletsIdx = 1;

        const { filteredPeriods, bulletThrows } = periods.reduce<{
            filteredPeriods: ScoreBoardScore[];
            bulletThrows: ScoreBoardScore[];
        }>(
            (acc, { number, periodType, home, away }) => {
                if (periodType === Regular || periodType === Overtime) {
                    acc.filteredPeriods.push({
                        title:
                            periodType === Overtime
                                ? `OVT${overTimeIdx > 1 ? overTimeIdx : ''}`
                                : `${number}`,
                        home: home.score.toString(),
                        away: away.score.toString(),
                        type: number === currentPeriod ? CurrentPart : Other,
                    });

                    if (periodType === Overtime) overTimeIdx += 1;
                }

                if (periodType === Bullets) {
                    if (number === currentPeriod) {
                        const homeBullet = home.bulletThrows || [];
                        const awayBullet = away.bulletThrows || [];
                        const lastBulletIdx =
                            max([homeBullet.length, awayBullet.length]) || 0;

                        const difference = lastBulletIdx - MAX_BULLET_SIZE;

                        const firstBulletIdx = difference > 0 ? difference : 0;

                        const isBulletIteration = (i: number) =>
                            !lastBulletIdx
                                ? i <= lastBulletIdx
                                : i < lastBulletIdx;

                        for (
                            let i = firstBulletIdx;
                            isBulletIteration(i);
                            i += 1
                        ) {
                            acc.bulletThrows.push({
                                title: `${i + 1}`,
                                home: `${getBulletThrowsValue(home, i)}`,
                                away: `${getBulletThrowsValue(away, i)}`,
                                type:
                                    homeBullet[i]?.active &&
                                    awayBullet[i]?.active
                                        ? CurrentPart
                                        : ExtraInfo,
                            });
                        }
                    }

                    acc.filteredPeriods.push({
                        title: `B${bulletsIdx > 1 ? bulletsIdx : ''}`,
                        home: home.score.toString(),
                        away: away.score.toString(),
                        type: number === currentPeriod ? CurrentPart : Other,
                    });

                    bulletsIdx += 1;
                }

                return acc;
            },
            {
                filteredPeriods: [],
                bulletThrows: [],
            }
        );

        if (isMatchListView) {
            return filteredPeriods.slice(-2);
        }

        return isShootout ? bulletThrows : filteredPeriods;
    },
    adaptFixtureInfo({ meta, competitors }, { translate }) {
        const currentPeriod = getMetaValueByName(meta, Period);
        const currentPeriodNumber = Number(
            getMetaValueByName(meta, PeriodNumber)
        );
        const time = Number(getMetaValueByName(meta, Time));
        const isTimerRunning = !!getMetaValueByName(meta, TimerRunning);

        const isShootout = currentPeriod === TYPE_BULLETS;
        const isOvertime = currentPeriod === TYPE_OVERTIME;

        const isZeroBased = isZeroBasedFixture(
            competitors[0].score,
            TYPE_PERIOD_REGULAR
        );

        return {
            timer: !isShootout
                ? {
                      time,
                      isTimerRunning,
                      isDecrement: true,
                  }
                : undefined,
            matchInfo: {
                title: getTitleInfo({
                    currentPeriodNumber,
                    isOvertime,
                    isShootout,
                    translate,
                    isZeroBased,
                }),
            },
            mainScoreType: Total,
        };
    },
    adaptFixtureScores({ competitors, meta }, { isMatchListView }) {
        const [home, away] = competitors;
        const homeScoresWithoutTotal = home.score.filter(
            (score) => score.type !== ScoreTypes.TYPE_TOTAL
        );
        const currentPeriod = getMetaValueByName(meta, Period);
        const currentPeriodNumber = Number(
            getMetaValueByName(meta, PeriodNumber)
        );

        const isShootout = currentPeriod === TYPE_BULLETS;
        const isOvertime = currentPeriod === TYPE_OVERTIME;

        const isZeroBased = isZeroBasedFixture(home.score, TYPE_PERIOD_REGULAR);

        const scoreList = homeScoresWithoutTotal.reduce<ScoreBoardScore[]>(
            (acc, score) => {
                if (score.type === TYPE_PERIOD_OVERTIME) {
                    acc.push({
                        home: score.points,
                        away: findAwayScore(competitors[1], score.id).points,
                        title: `OVT${score.number > 1 ? score.number : ''}`,
                        type:
                            isOvertime && score.number === currentPeriodNumber
                                ? ScoreType.CurrentPart
                                : ScoreType.Other,
                    });

                    return acc;
                }

                if (score.type === TYPE_PERIOD_BULLETS) {
                    acc.push({
                        home: score.points,
                        away: findAwayScore(competitors[1], score.id).points,
                        title: `B${score.number > 1 ? score.number : ''}`,
                        type: isShootout
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                    });

                    return acc;
                }

                acc.push({
                    home: score.points,
                    away: findAwayScore(away, score.id).points,
                    title: `${isZeroBased ? score.number + 1 : score.number}`,
                    type:
                        score.number === currentPeriodNumber
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                });

                return acc;
            },
            []
        );

        if (isMatchListView) {
            return scoreList.slice(-2);
        }

        return scoreList;
    },
};

const getBulletThrowsValue = (
    team: IceHockeyOverviewPeriodTeam,
    idx: number
): string => {
    if (!team.bulletThrows || idx > team.bulletThrows.length) return 'x';

    return team.bulletThrows[idx]?.scored ? '1' : 'x';
};

const getTitleInfo = ({
    currentPeriodNumber,
    pause,
    isOvertime,
    isShootout,
    translate,
    isZeroBased,
}: GetTitleInput): string => {
    const { tSpecifier } = translate;

    if (pause) {
        return tSpecifier('break');
    }

    if (isOvertime) {
        return tSpecifier('overtimenr');
    }

    if (isShootout) {
        return tSpecifier('shootout');
    }

    return `${
        isZeroBased ? currentPeriodNumber + 1 : currentPeriodNumber
    } ${tSpecifier('periodnr')}`;
};

export default iceHockeyAdapter;
