import cn from 'classnames';

import { IndoorSoccer, IndoorSoccerPeriodType } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreBoardScore, ScoreType } from '../types';

type FootballHeaderByScoreType = {
    [key in IndoorSoccerPeriodType]?: string;
};

export const indoorSoccerHeaderByPeriodType: FootballHeaderByScoreType = {
    [IndoorSoccerPeriodType.FirstHalf]: '1',
    [IndoorSoccerPeriodType.SecondHalf]: '2',
};

const indoorSoccerAdapter: ScoreboardAdapter<IndoorSoccer> = {
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { timer, currentPeriod } = overview;

        const title =
            currentPeriod === IndoorSoccerPeriodType.Unknown
                ? tSpecifier('timeout')
                : `${
                      indoorSoccerHeaderByPeriodType[currentPeriod]
                  } ${tSpecifier('time')}`;

        return {
            mainScoreType: ScoreType.Total,
            timer: {
                time: timer.time,
                isTimerRunning: timer.isActive,
                isDecrement: false,
            },
            matchInfo: { title },
        };
    },
    adaptOverviewScores(overview, { isMatchListView }) {
        const { teams, currentPeriod, periods } = overview;

        const result: ScoreBoardScore[] = [];
        const cardStyles = cn('h-3 w-[10px] rounded-default');
        const titles = {
            yellowCards: <div className={cn(cardStyles, 'bg-yellow-500')} />,
            redCards: <div className={cn(cardStyles, 'bg-red-900')} />,
        } as const;

        if (!periods) return result;

        const scoresList = periods.map(
            ({ type, teams: periodTeams, number }) => {
                const score: ScoreBoardScore = {
                    title: number.toString(),
                    home: periodTeams.home.score.toString(),
                    away: periodTeams.away.score.toString(),
                    type:
                        type === currentPeriod
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                };

                return score;
            }
        );

        if (isMatchListView) {
            return teams.home.redCards || teams.away.redCards
                ? [
                      {
                          home: teams.home.redCards.toString(),
                          away: teams.away.redCards.toString(),
                          title: titles.redCards,
                          type: ScoreType.ExtraInfo,
                      },
                      ...scoresList,
                  ]
                : scoresList;
        }

        (['yellowCards', 'redCards'] as const).forEach((key) => {
            const infoScore: ScoreBoardScore = {
                home: teams.home[key].toString(),
                away: teams.away[key].toString(),
                title: titles[key],
                type: ScoreType.ExtraInfo,
            };

            result.push(infoScore);
        });

        result.push(...scoresList);

        return result;
    },
};

export default indoorSoccerAdapter;
