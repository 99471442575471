import { FC } from 'react';
import cn from 'classnames';
import { map } from 'lodash';
import type { Overview, PartialMatch, PartialMatchMarkets } from 'types';

import { ScoreTypes } from 'app-constants';
import TopMarkets from 'components/betting/TopMarkets';
import SmartLink from 'components/SmartLink';
import { Feature, useFeatureFlags } from 'services/features';
import { Action, useAnalyticAttrs } from 'services/GoogleAnalytics';
import { Competitor, CompetitorHomeAway, CompetitorScore } from 'types/gql.bet';
import { isMultiCompetitorsMatch } from 'utils';
import { OriginType } from '../constants';
import MultiCompetitorsOverviewRow from '../MultiCompetitorsOverviewRow';
import SportEventRowMainInfo from '../SportEventRowMainInfo/SportEventRowMainInfo';

interface Props {
    matchRoute: string;
    fixture: PartialMatch['fixture'];
    sportEventId: string;
    isAvailableStream: boolean;
    isResizable: boolean;
    matchDay: string;
    matchTime: string;
    overview: Overview | undefined;
    markets: PartialMatchMarkets;
    isPrematchView: boolean;
    withTournamentHeader: boolean;
    meta: PartialMatch['meta'];
    origin: OriginType;
}

const SportEventRowBody: FC<Props> = ({
    matchRoute,
    fixture,
    sportEventId,
    isAvailableStream,
    isResizable,
    matchDay,
    matchTime,
    overview,
    markets,
    isPrematchView,
    withTournamentHeader,
    origin,
    meta,
}) => {
    const { features } = useFeatureFlags();
    const isMultiCompetitorsOverview = features.isEnabled(
        Feature.MatchListMultiCompetitorsOverview
    );

    const isMultiCompetitors =
        isMultiCompetitorsMatch(fixture.competitors) &&
        isMultiCompetitorsOverview;

    const analytic = useAnalyticAttrs({
        defaultAttrs: {
            'data-action': Action.ClickOnLink,
        },
    });

    const dividerStyles = cn(
        "after:absolute after:left-0 after:top-[48px] after:h-[1px] after:w-full after:bg-surface-dark after:content-['']"
    );

    return (
        <SmartLink
            href={matchRoute}
            analyticsAttrs={{
                'data-category': analytic['data-category'],
                'data-action': analytic['data-action'],
            }}
        >
            {isMultiCompetitors ? (
                <MultiCompetitorsOverviewRow
                    sportEventId={sportEventId}
                    isAvailableStream={isAvailableStream}
                    isResizable={isResizable}
                    matchDay={matchDay}
                    matchTime={matchTime}
                    isPrematchView={isPrematchView}
                    overview={overview}
                    meta={meta}
                    competitors={setDefaultScore(
                        fixture.competitors,
                        fixture.score.split(':')
                    )}
                    sportId={fixture.sportId}
                    markets={markets}
                />
            ) : (
                <div
                    className={cn(
                        dividerStyles,
                        'relative flex flex-col bg-surface-middle p-4 pt-0 ',
                        {
                            ['mb-2 sm:h-[185px] sm:flex-row sm:gap-x-6']:
                                isResizable,
                        },
                        {
                            'items-center': isPrematchView,
                        },
                        {
                            'h-full justify-between':
                                isPrematchView &&
                                !isResizable &&
                                !withTournamentHeader,
                        },
                        { 'h-[248px]': origin === OriginType.Carousel }
                    )}
                >
                    <SportEventRowMainInfo
                        overview={overview}
                        meta={meta}
                        competitors={setDefaultScore(
                            fixture.competitors,
                            fixture.score.split(':')
                        )}
                        isPrematchView={isPrematchView}
                        isAvailableStream={isAvailableStream}
                        matchDay={matchDay}
                        matchTime={matchTime}
                        sportId={fixture.sportId}
                        isResizable={isResizable}
                        sportEventId={sportEventId}
                    />

                    <div
                        className={cn(
                            'flex min-h-[64px] w-full overflow-hidden',
                            !isResizable && isPrematchView ? 'h-auto' : 'h-full'
                        )}
                    >
                        <TopMarkets
                            markets={markets}
                            withName={isResizable}
                            sportEventId={sportEventId}
                            isResizable={isResizable}
                        />
                    </div>
                </div>
            )}
        </SmartLink>
    );
};

const getTotalScore = (path: string, value?: string): CompetitorScore => ({
    __typename: 'CompetitorScore',
    id: 'total',
    type: ScoreTypes.TYPE_TOTAL,
    points: value || '0',
    number: 0,
    path: `${path}~total`,
});

const setDefaultScore = (
    competitors: PartialMatch['fixture']['competitors'] | Competitor[],
    score: string[]
): Competitor[] => {
    return map(competitors, (competitor) => {
        if ('score' in competitor) {
            return competitor as Competitor;
        }

        return {
            ...competitor,
            score: [
                getTotalScore(
                    competitor.path,
                    competitor.homeAway === CompetitorHomeAway.Home
                        ? score[0]
                        : score[1]
                ),
            ],
        } as Competitor;
    });
};

export default SportEventRowBody;
