import { fillLink } from 'cl-fill-link';
import type { PartialMatch } from 'types';

import { useRouter } from 'components/HybridRouter';
import { CompetitorType } from 'types/gql.bet';
import { getHomeAwayCompetitors } from 'utils';
import { useLocales, useLocalizedCountryName } from '../../hooks';

interface Input {
    fixture: PartialMatch['fixture'];
}

type SeoMicromarking = {
    '@context': 'http://schema.org';
    '@type': 'SportsEvent';
    name: string;
    url: string;
    image?: string;
    startDate: string;
    endDate?: string;
    sport: string;
    location: {
        '@type': 'Place';
        address: {
            '@type': 'PostalAddress';
            addressCountry: {
                '@type': 'Country';
                name: string;
            };
        };
    };
    competitor?: Array<{
        '@type': 'Person';
        name: string;
        image?: string;
    }>;
    hometeam?: Array<{
        '@type': 'SportsTeam';
        name: string;
        image?: string;
        sport: string;
    }>;
    awayteam?: Array<{
        '@type': 'SportsTeam';
        name: string;
        image?: string;
        sport: string;
    }>;
} | null;

type Schema = {
    sportName: string;
    sportEventStartTime: string;
    sportEventName: string;
    tournamentLogo: string;
    url: string;
    country: string;
    competitors: {
        homeTeam: string;
        awayTeam: string;
        logoHome: string;
        awayLogo: string;
    };
};

interface Output {
    seoMicromarking: SeoMicromarking;
}

const useSportEventSeoMicromarking = ({ fixture }: Input): Output => {
    const { activeLocale } = useLocales();
    const [homeCompetitor, awayCompetitor] = getHomeAwayCompetitors(
        fixture.competitors.map((competitor) => ({
            ...competitor,
            score: competitor.score || [],
        }))
    );
    const { pathname, query } = useRouter();
    const countryName = useLocalizedCountryName(
        fixture.tournament.countryCode ?? ''
    );
    const isCompetitorTypeTeam = homeCompetitor.type === CompetitorType.Team;

    if (!fixture) {
        return {
            seoMicromarking: null,
        };
    }

    const schema: Schema = {
        tournamentLogo: makeUrlWithProtocol(fixture.tournament.logo),
        sportEventName: fixture.title,
        sportEventStartTime: fixture.tournament.dateStart,
        sportName: fixture.sport.name,
        url: makeUrlWithProtocol(fillLink(pathname, query), activeLocale),
        country: countryName.length ? countryName : 'Word',
        competitors: {
            homeTeam: homeCompetitor.name,
            awayTeam: awayCompetitor.name,
            logoHome: makeUrlWithProtocol(homeCompetitor.logo),
            awayLogo: makeUrlWithProtocol(awayCompetitor.logo),
        },
    };

    const seoMicromarking: SeoMicromarking = {
        '@context': 'http://schema.org',
        '@type': 'SportsEvent',
        name: schema.sportEventName,
        url: schema.url,
        ...(schema.tournamentLogo.length && { image: schema.tournamentLogo }),
        startDate: schema.sportEventStartTime,
        sport: schema.sportName,
        location: {
            '@type': 'Place',
            address: {
                '@type': 'PostalAddress',
                addressCountry: {
                    '@type': 'Country',
                    name: schema.country,
                },
            },
        },
    };

    if (isCompetitorTypeTeam) {
        seoMicromarking.hometeam = [
            {
                '@type': 'SportsTeam',
                name: schema.competitors.homeTeam,
                ...(schema.competitors.logoHome.length && {
                    image: schema.competitors.logoHome,
                }),
                sport: schema.sportName,
            },
        ];

        seoMicromarking.awayteam = [
            {
                '@type': 'SportsTeam',
                name: schema.competitors.awayTeam,
                ...(schema.competitors.awayLogo.length && {
                    image: schema.competitors.awayLogo,
                }),
                sport: schema.sportName,
            },
        ];
    } else {
        seoMicromarking.competitor = [
            {
                '@type': 'Person',
                name: schema.competitors.homeTeam,
                ...(schema.competitors.logoHome.length && {
                    image: schema.competitors.logoHome,
                }),
            },
            {
                '@type': 'Person',
                name: schema.competitors.awayTeam,
                ...(schema.competitors.awayLogo.length && {
                    image: schema.competitors.awayLogo,
                }),
            },
        ];
    }

    return { seoMicromarking };
};

const makeUrlWithProtocol = (url: string, activeLocale?: string) => {
    if (!url) return '';

    return `https://${activeLocale || ''}${url}`;
};

export default useSportEventSeoMicromarking;
