import React, { FC, MouseEventHandler, useCallback } from 'react';

import {
    ScoreBoardMatchInfo,
    ScoreboardTimer,
} from 'components/betting/Match/ScoreBoard/types';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { withClientRender } from 'hocs';
import { useTimer } from 'hooks';
import Info from '../Info';

interface Props {
    matchInfo: ScoreBoardMatchInfo;
    subTitle?: string | JSX.Element;
    timer?: ScoreboardTimer;
    isAvailableStream: boolean;
}

const LiveFixture: FC<Props> = ({ matchInfo, timer, isAvailableStream }) => {
    const { title, subTitle } = matchInfo;
    const disableClickHandler: MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.preventDefault();
        },
        []
    );

    return (
        <div className="flex items-center" onClick={disableClickHandler}>
            <Info
                text={title}
                subtext={subTitle || (timer ? <Timer timer={timer} /> : '')}
            />
            {isAvailableStream && (
                <PackIcon
                    id="play-icon"
                    pack={IconsPack.SpriteIcons}
                    className="ml-2 fill-red-700"
                />
            )}
        </div>
    );
};

const Timer: React.FC<{ timer: ScoreboardTimer }> = ({ timer }) => {
    const { timeToShow } = useTimer(timer);

    return <span>{timeToShow}</span>;
};

export default withClientRender(LiveFixture);
