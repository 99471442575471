import cn from 'classnames';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import {
    EFootball,
    EFootballPeriod,
    Football,
    FootballPenaltyStatus,
    FootballPeriod,
    PenaltyStatus,
} from 'types/gql.bet';
import {
    Flatten,
    ScoreboardAdapter,
    ScoreBoardScore,
    ScoreType,
} from '../types';
import { getMessage } from './utils';

type Periods<
    T extends { periods?: Football['periods'] | EFootball['periods'] },
> = NonNullable<Flatten<T['periods']>>;

type Penalties<
    T extends {
        home: {
            penalties?: Array<{
                number: number;
                statusEFootball?: PenaltyStatus;
                status?: FootballPenaltyStatus;
            }> | null;
        };
    },
> = Flatten<T['home']['penalties']>;

type FootballHeaderByScoreType = {
    [key in FootballPeriod]?: string;
};

export const footballHeaderByScoreType: FootballHeaderByScoreType = {
    [FootballPeriod.FirstHalf]: '1',
    [FootballPeriod.SecondHalf]: '2',
    [FootballPeriod.FirstExtra]: '1EH',
    [FootballPeriod.SecondExtra]: '2EH',
    [FootballPeriod.Penalties]: 'P',
};

const extraTimeAndPenaltiesHeader = [
    footballHeaderByScoreType.FIRST_EXTRA,
    footballHeaderByScoreType.SECOND_EXTRA,
    footballHeaderByScoreType.PENALTIES,
];

const footballAdapter: ScoreboardAdapter<Football | EFootball> = {
    adaptOverviewInfo(overview, { translate }) {
        const { timer, pause } = overview;

        const currentMatchPart = isFootball(overview)
            ? overview.currentFootballMatchPart
            : overview.currentEFootballMatchPart;
        const isVarActive = isFootball(overview) && overview?.isVarActive;

        let penaltiesScore = '';

        if (currentMatchPart === FootballPeriod.Penalties) {
            const penaltiesPeriod = isFootball(overview)
                ? overview.periods?.find(
                      ({ period }) => period === FootballPeriod.Penalties
                  )
                : overview.periods?.find(
                      ({ periodEFootball }) =>
                          periodEFootball === EFootballPeriod.Penalties
                  );

            const afterMatchPenaltiesHome = penaltiesPeriod?.home.score || 0;
            const afterMatchPenaltiesAway = penaltiesPeriod?.away.score || 0;

            penaltiesScore = `${afterMatchPenaltiesHome}:${afterMatchPenaltiesAway}`;
        }

        return {
            mainScoreType: ScoreType.Total,
            headerIcon: isVarActive
                ? {
                      icon: (
                          <PackIcon
                              id="var-icon"
                              pack={IconsPack.SpriteIcons}
                              size={SvgIconSize.WH_4}
                          />
                      ),
                  }
                : undefined,
            timer:
                currentMatchPart !== FootballPeriod.Penalties
                    ? {
                          isTimerRunning: timer.isActive && !pause,
                          time: timer.time,
                          isDecrement: false,
                      }
                    : undefined,
            matchInfo: {
                title: getMessage(
                    currentMatchPart,
                    penaltiesScore,
                    translate,
                    pause
                ),
            },
        };
    },
    adaptOverviewScores(overview, { isMatchListView, isMobile }) {
        const { teams } = overview;

        const currentMatchPart = isFootball(overview)
            ? overview.currentFootballMatchPart
            : overview.currentEFootballMatchPart;
        const periods = isFootball(overview)
            ? overview.periods
            : overview.periods;

        const result: ScoreBoardScore[] = [];
        const iconStyles = cn('fill-primary-white');
        const cardStyles = cn('h-3 w-[10px] rounded-default');
        const titles = {
            penalties: (
                <PackIcon
                    id="penalty"
                    pack={IconsPack.SpriteIcons}
                    className={iconStyles}
                    size={SvgIconSize.WH_4}
                />
            ),
            cornerKicks: (
                <PackIcon
                    id="corner-kick"
                    pack={IconsPack.SpriteIcons}
                    className={iconStyles}
                    size={SvgIconSize.WH_4}
                />
            ),
            yellowCards: <div className={cn(cardStyles, 'bg-yellow-500')} />,
            redCards: <div className={cn(cardStyles, 'bg-red-900')} />,
        } as const;

        const penaltiesCount = {
            home: 0,
            away: 0,
        };

        if (!periods) return result;

        const scoresList = periods.map((item) => {
            const period = isFootballPeriod(item)
                ? item.period
                : item.periodEFootball;

            if (period !== FootballPeriod.Penalties) {
                (['home', 'away'] as const).forEach((side) => {
                    penaltiesCount[side] += getPeriodPenaltiesCount(
                        item[side].penalties || []
                    );
                });
            }

            const score: ScoreBoardScore = {
                title: footballHeaderByScoreType[period] || '',
                home: item.home.score.toString(),
                away: item.away.score.toString(),
                type:
                    period === currentMatchPart
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            };

            return score;
        });

        if (isMatchListView) {
            const lastTwoScores = scoresList.slice(-2);

            return teams.home.redCards || teams.away.redCards
                ? [
                      {
                          home: teams.home.redCards.toString(),
                          away: teams.away.redCards.toString(),
                          title: titles.redCards,
                          type: ScoreType.ExtraInfo,
                      },
                      ...lastTwoScores,
                  ]
                : lastTwoScores;
        }

        if (isFootball(overview)) {
            result.push({
                home: overview.teams.home.cornerKicks.toString(),
                away: overview.teams.away.cornerKicks.toString(),
                title: titles.cornerKicks,
                type: ScoreType.ExtraInfo,
            });
        }

        (['yellowCards', 'redCards'] as const).forEach((key) => {
            const infoScore: ScoreBoardScore = {
                home: teams.home[key].toString(),
                away: teams.away[key].toString(),
                title: titles[key],
                type: ScoreType.ExtraInfo,
            };

            result.push(infoScore);
        });

        result.push(...scoresList);

        result.unshift({
            home: penaltiesCount.home.toString(),
            away: penaltiesCount.away.toString(),
            type: ScoreType.ExtraInfo,
            title: titles.penalties,
        });

        return isMobile
            ? result.filter(
                  ({ title }) =>
                      !extraTimeAndPenaltiesHeader.includes(title as string)
              )
            : result;
    },
};

function getPeriodPenaltiesCount(
    penalties: Array<
        Penalties<Periods<Football>> | Penalties<Periods<EFootball>>
    >
): number {
    return penalties.filter((penalty) => {
        const status = isFootballPenalties(penalty)
            ? penalty?.status
            : penalty.statusEFootball;

        return status === FootballPenaltyStatus.Ended;
    }).length;
}

const isFootball = (overview: Football | EFootball): overview is Football =>
    overview.__typename === 'FootballOverview';

const isFootballPeriod = (
    period: Periods<Football> | Periods<EFootball>
): period is Periods<Football> =>
    period?.__typename === 'FootballOverviewPeriod';

const isFootballPenalties = (
    penalty: Penalties<Periods<Football>> | Penalties<Periods<EFootball>>
): penalty is Penalties<Periods<Football>> =>
    penalty?.__typename === 'FootballOverviewPenalties';

export default footballAdapter;
