import {
    SCORE_TYPES_TITLE_MAPPING,
    ScoreTypes,
    SportEventMetaKey,
} from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';
import { adaptFixtureScores, findAwayScore } from './utils';

const { Inning } = SportEventMetaKey;

const baseballAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta }) {
        const inning = getMetaValueByName(meta, Inning) || 0;

        return {
            matchInfo: {
                title: inning
                    ? `${inning}${SCORE_TYPES_TITLE_MAPPING.inning_points}`
                    : '',
            },
            mainScoreType: ScoreType.Total,
        };
    },
    adaptFixtureScores({ competitors, meta }, { isMatchListView }) {
        const scoreList = adaptFixtureScores(competitors, {
            scoreIteratee: (score) => {
                if (score.type !== ScoreTypes.TYPE_INNING_POINTS) return false;

                const inning = +(getMetaValueByName(meta, Inning) || 0);

                return {
                    home: score.points,
                    away: findAwayScore(competitors[1], score.id).points,
                    title: `${score.number}`,
                    type:
                        score.number === inning
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                };
            },
        });

        if (isMatchListView) {
            return scoreList.slice(-3);
        }

        return scoreList;
    },
};

export default baseballAdapter;
