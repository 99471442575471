import { FC, memo } from 'react';
import { useTranslations } from 'next-intl';

import Competitor from 'components/betting/Competitor';
import { adaptSport } from 'components/betting/Match/ScoreBoard/adapter';
import { Action, useAnalyticAttrs } from 'services/GoogleAnalytics';
import { Overview } from 'types';
import {
    Competitor as CompetitorType,
    MatchBaseWithOptionalMeta,
} from 'types/gql.bet';
import OverviewScores from '../../OverviewScores';
import LiveFixture from '../LiveFixture';

interface Props {
    competitors: CompetitorType[];
    isAvailableStream: boolean;
    overview?: Overview;
    sportId: string;
    meta: MatchBaseWithOptionalMeta['meta'];
}

const LiveMainInfo: FC<Props> = ({
    competitors,
    isAvailableStream,
    overview,
    sportId,
    meta,
}) => {
    const tSpecifier = useTranslations('betting-specifiers.specifiers');

    const {
        scores,
        totalScore,
        colors,
        matchInfo,
        timer,
        competitors: scoreboardCompetitors,
    } = adaptSport({
        overview,
        sportId,
        competitors,
        translate: {
            tSpecifier,
        },
        meta,
        isMatchListView: true,
    });

    const analytic = useAnalyticAttrs({
        defaultAttrs: {
            'data-action': Action.ClickOnTitle,
        },
    });

    return (
        <>
            <div className="flex w-0 min-w-0 grow flex-col items-start gap-4  bg-surface-middle ">
                <div className="h-12">
                    <LiveFixture
                        matchInfo={matchInfo}
                        isAvailableStream={isAvailableStream}
                        timer={timer}
                    />
                </div>

                <div
                    className="flex h-full w-full items-end self-start bg-surface-middle"
                    data-test="live-main-info__games"
                >
                    <div
                        className="mb-2 flex w-full flex-col justify-between gap-4"
                        data-category={analytic['data-category']}
                        data-action={analytic['data-action']}
                    >
                        {scoreboardCompetitors.map((competitor, index) => (
                            <Competitor
                                {...competitor}
                                key={index}
                                displayType="row"
                            />
                        ))}
                    </div>
                </div>
            </div>

            <OverviewScores
                scores={scores || []}
                totalScore={totalScore}
                colors={colors}
                sportId={sportId}
            />
        </>
    );
};

export default memo(LiveMainInfo);
