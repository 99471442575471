import { useRouter } from 'components/HybridRouter';
import { usePagePropsContext } from 'layouts/Root/PagePropsContext';
import { Feature, useFeatureFlags } from 'services/features';
import { Pair } from 'types/api.bet';
import { Stream } from 'types/gql.bet';
import { getActiveLocale, getMatchTrackerByMeta } from 'utils';
import { PlayerType } from './constants';
import { findStream, getPlayerType } from './utils';

const disabledDomains: Array<string> = [];

interface Input {
    streams: Stream[];
    meta?: Pair[];
}

interface Output {
    isAvailableMatchTracker: boolean;
    isStreamEnable: boolean;
    betradarId: string;
    currentStream?: Stream;
}

const useBroadcastHelper = ({ streams, meta }: Input): Output => {
    const router = useRouter();
    const activeLocale = getActiveLocale(router);
    const { host } = usePagePropsContext();
    const { features } = useFeatureFlags();

    const { lmtCoverageLevel, betradarId } = getMatchTrackerByMeta(meta);
    const isAvailableMatchTracker =
        !!betradarId && !!lmtCoverageLevel && lmtCoverageLevel !== '1.00';

    const currentStream = findStream(streams, activeLocale);

    const playerType = getPlayerType(currentStream?.url || '');

    const isFeaturesTwichPlayerDisable = features.isEnabled(
        Feature.AppDisableTwichPlayer
    );

    const isDisabledDomain = disabledDomains.includes(host);

    const isTwichPlayerDisable =
        playerType === PlayerType.Twitch &&
        isFeaturesTwichPlayerDisable &&
        isDisabledDomain;

    return {
        betradarId,
        currentStream,
        isAvailableMatchTracker,
        isStreamEnable: !!currentStream && !isTwichPlayerDisable,
    };
};

export default useBroadcastHelper;
