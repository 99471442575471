import { capitalize, find, includes } from 'lodash';

import type { BrawlStars } from 'types/gql.bet';
import { TeamHomeAway, WinnerHomeAway } from 'types/gql.bet';
import { Flatten, ScoreboardAdapter, ScoreType } from '../types';

type MapsTypes<T extends { maps?: BrawlStars['maps'] }> = NonNullable<
    Flatten<T['maps']>
>;

const firstBlood = [WinnerHomeAway.Home, WinnerHomeAway.Away];

const brawlStarsAdapter: ScoreboardAdapter<BrawlStars> = {
    adaptOverviewScores(overview, { isMatchListView }) {
        const { currentMap, maps } = overview;

        const scores =
            maps?.map(({ number, winner }) => ({
                title: `${number}`,
                home: getScore(winner, TeamHomeAway.Home),
                away: getScore(winner, TeamHomeAway.Away),
                type: ScoreType.Other,
            })) || [];

        const currentFirstBlood = find<MapsTypes<BrawlStars>>(
            maps,
            ({ number }) => number === currentMap
        )?.firstBlood;

        const isFirstBlood = includes(firstBlood, currentFirstBlood);

        if (isFirstBlood && !isMatchListView) {
            scores.unshift({
                title: `FB`,
                home: currentFirstBlood === WinnerHomeAway.Home ? '+' : '',
                away: currentFirstBlood === WinnerHomeAway.Away ? '+' : '',
                type: ScoreType.ExtraInfo,
            });
        }

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { currentMap: currentMapNumber, bestOf } = overview;

        const matchFormat = bestOf ? `BO${bestOf}:` : '';
        const matchPart = currentMapNumber
            ? `${capitalize(tSpecifier('map'))} ${currentMapNumber}`
            : '';
        const title = `${matchFormat} ${matchPart}`;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.Total,
        };
    },
};

export const getScore = (winner: TeamHomeAway, side: TeamHomeAway): string => {
    if (winner === TeamHomeAway.Unknown) {
        return '-';
    }

    return side === winner ? 'W' : 'L';
};

export default brawlStarsAdapter;
