import {
    SCORE_TYPES_TITLE_MAPPING,
    ScoreTypes,
    SportEventMetaKey,
} from 'app-constants';
import { Sports } from 'components/betting/Categorizer/constants';
import {
    AdaptFixtureArgs,
    ScoreBoard,
    ScoreboardInfo,
    ScoreBoardScore,
    ScoreType,
    TranslateScoreboard,
} from 'components/betting/Match/ScoreBoard/types';
import { Overview } from 'types';
import {
    Competitor,
    MatchBaseWithOptionalMeta,
    MmaWinner,
    TeamHomeAway,
} from 'types/gql.bet';
import { getMetaValueByName, getTotalScore } from 'utils';
import { getHomeAwayCompetitors } from 'utils/getHomeAwayCompetitors';
import americanFootballAdapter from './americanFootballAdapter';
import badmintonAdapter from './badmintonAdapter';
import baseballAdapter from './baseballAdapter';
import basketballAdapter from './basketballAdapter';
import battleRoyalAdapter from './battleRoyalAdapter';
import brawlStarsAdapter from './brawlStarsAdapter';
import cricketAdapter from './cricketAdapter';
import csgoAdapter from './csgoAdapter';
import dota2Adapter from './dota2Adapter';
import eVolaFootballAdapter from './eVoltaFootballAdapter';
import footballAdapter from './footballAdapter';
import footballFixtureAdapter from './footballFixtureAdapter';
import futsalAdapter from './futsalAdapter';
import iceHockeyAdapter from './iceHockeyAdapter';
import indoorSoccerAdapter from './indoorSoccerAdapter';
import lolAdapter from './lolAdapter';
import mmaAdapter, { getWinner } from './mmaAdapter';
import mobileMobaAdapter from './mobileMobaAdapter';
import mortalKombatAdapter from './mortalKombatAdapter';
import rainbow6Adapter from './rainbow6Adapter';
import rocketLeagueAdapter from './rocketLeagueAdapter';
import starCraftAdapter from './starCraftAdapter';
import tableTennisAdapter from './tableTennisAdapter';
import tennisAdapter from './tennisAdapter';
import { adaptFixtureScores } from './utils';
import valorantAdapter from './valorantAdapter';
import volleyballAdapter from './volleyballAdapter';

interface Input {
    isMobile?: boolean;
    sportId: string;
    competitors: Competitor[];
    overview?: Overview;
    translate: TranslateScoreboard;
    meta: MatchBaseWithOptionalMeta['meta'];
    isMatchListView?: boolean;
}

export function adaptSport({
    isMobile,
    sportId,
    competitors,
    overview,
    translate,
    meta = [],
    isMatchListView = false,
}: Input): ScoreBoard {
    let result = {} as ScoreBoard;

    const homeAwayCompetitors = getHomeAwayCompetitors(competitors);

    result.competitors = homeAwayCompetitors.map(
        ({ id, logo, name, type, slug }) => {
            return {
                id,
                logo,
                name,
                type,
                slug,
                isServer: false,
            };
        }
    );
    result.totalScore = {
        home: getTotalScore(homeAwayCompetitors[0]),
        away: getTotalScore(homeAwayCompetitors[1]),
        type: ScoreType.Total,
        title: SCORE_TYPES_TITLE_MAPPING[ScoreTypes.TYPE_TOTAL],
    };

    let scores: ScoreBoardScore[] = [];
    let info: ScoreboardInfo = {
        mainScoreType: ScoreType.Total,
        matchInfo: {
            title: '',
        },
    };

    const fixtureArgs: AdaptFixtureArgs = {
        competitors: homeAwayCompetitors,
        meta,
    };

    const options = {
        isMatchListView,
        isMobile,
        translate,
    };

    switch (sportId) {
        case Sports.ESPORTS_COUNTER_STRIKE: {
            if (overview && overview.__typename === 'CSGOOverview') {
                scores = csgoAdapter.adaptOverviewScores(overview, options);
                info = csgoAdapter.adaptOverviewInfo(overview, options);
            }

            break;
        }

        case Sports.ESPORTS_DOTA_2: {
            if (overview && overview.__typename === 'Dota2Overview') {
                scores = dota2Adapter.adaptOverviewScores(overview, options);
                info = dota2Adapter.adaptOverviewInfo(overview, options);
            }

            break;
        }

        case Sports.ESPORTS_LEAGUE_OF_LEGENDS: {
            if (overview && overview.__typename === 'LOLOverview') {
                scores = lolAdapter.adaptOverviewScores(overview, options);
                info = lolAdapter.adaptOverviewInfo(overview, options);
            }

            break;
        }

        case Sports.CRICKET: {
            if (overview && overview.__typename === 'CricketOverview') {
                scores = cricketAdapter.adaptOverviewScores(overview, options);
                info = cricketAdapter.adaptOverviewInfo(overview, options);
                result.totalScore.home = overview.homePoints.toString();
                result.totalScore.away = overview.awayPoints.toString();
            }

            break;
        }

        case Sports.AMERICAN_FOOTBALL: {
            if (
                overview &&
                overview.__typename === 'AmericanFootballOverview'
            ) {
                defineCompetitorServer(overview.attackSide, result);

                scores = americanFootballAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = americanFootballAdapter.adaptOverviewInfo(
                    overview,
                    options
                );
            }

            break;
        }

        case Sports.ESPORTS_STARCRAFT:
        case Sports.ESPORTS_STARCRAFT_1:
        case Sports.ESPORTS_HEARTHSTONE:
        case Sports.ESPORTS_OVERWATCH: {
            if (
                overview &&
                (overview.__typename === 'StarCraft1Overview' ||
                    overview.__typename === 'StarCraftOverview' ||
                    overview.__typename === 'HearthstoneOverview' ||
                    overview.__typename === 'OverwatchOverview')
            ) {
                scores = starCraftAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = starCraftAdapter.adaptOverviewInfo(overview, options);
                result.totalScore.home = overview.teams.home.score.toString();
                result.totalScore.away = overview.teams.away.score.toString();
            }

            break;
        }

        case Sports.FOOTBALL:
        case Sports.ESPORTS_FIFA: {
            if (
                overview &&
                (overview.__typename === 'FootballOverview' ||
                    overview.__typename === 'EFootballOverview')
            ) {
                scores = footballAdapter.adaptOverviewScores(overview, options);
                info = footballAdapter.adaptOverviewInfo(overview, options);
                result.totalScore.home = overview.teams.home.score.toString();
                result.totalScore.away = overview.teams.away.score.toString();
                break;
            }

            scores = footballFixtureAdapter.adaptFixtureScores(
                fixtureArgs,
                options
            );
            info = footballFixtureAdapter.adaptFixtureInfo(
                fixtureArgs,
                options
            );
            break;
        }

        case Sports.FUTSAL: {
            if (overview && overview.__typename === 'FutsalOverview') {
                scores = futsalAdapter.adaptOverviewScores(overview, options);
                info = futsalAdapter.adaptOverviewInfo(overview, options);
                result.totalScore.home = overview.teams.home.score.toString();
                result.totalScore.away = overview.teams.away.score.toString();
            }

            break;
        }

        case Sports.MMA: {
            if (overview && overview.__typename === 'MMAOverview') {
                scores = mmaAdapter.adaptOverviewScores(overview, options);
                info = mmaAdapter.adaptOverviewInfo(overview, options);
                const totalScore = {
                    home: isMatchListView
                        ? '0'
                        : getWinner({
                              side: MmaWinner.Home,
                              result: overview.result,
                          }),
                    away: isMatchListView
                        ? '0'
                        : getWinner({
                              side: MmaWinner.Away,
                              result: overview.result,
                          }),
                };
                result.totalScore.home = totalScore.home;
                result.totalScore.away = totalScore.away;
            }

            break;
        }

        case Sports.TENNIS:
        case Sports.ESPORTS_TENNIS: {
            if (
                overview &&
                (overview.__typename === 'TennisOverview' ||
                    overview.__typename === 'ETennisOverview')
            ) {
                defineCompetitorServer(overview.server, result);

                scores = tennisAdapter.adaptOverviewScores(overview, options);
                info = tennisAdapter.adaptOverviewInfo(overview, options);

                break;
            }

            if (sportId === Sports.TENNIS) {
                scores = tennisAdapter.adaptFixtureScores(fixtureArgs, options);
                info = tennisAdapter.adaptFixtureInfo(fixtureArgs, options);

                defineServerFromMeta(meta, result);
            }

            break;
        }

        case Sports.TABLE_TENNIS: {
            if (overview && overview.__typename === 'TableTennisOverview') {
                defineCompetitorServer(overview.server, result);

                scores = tableTennisAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = tableTennisAdapter.adaptOverviewInfo(overview, options);

                break;
            }

            scores = tableTennisAdapter.adaptFixtureScores(
                fixtureArgs,
                options
            );
            info = tableTennisAdapter.adaptFixtureInfo(fixtureArgs, options);

            defineServerFromMeta(meta, result);
            break;
        }

        case Sports.ESPORTS_STREETBALL: {
            if (overview && overview.__typename === 'EStreetballOverview') {
                defineCompetitorServer(overview.ballPossessionTeam, result);
                result.totalScore.home = overview.homePoints.toString();
                result.totalScore.away = overview.awayPoints.toString();
            }

            break;
        }

        case Sports.BASKETBALL:
        case Sports.ESPORTS_BASKETBALL: {
            if (
                overview &&
                (overview.__typename === 'BasketballOverview' ||
                    overview.__typename === 'EBasketballOverview')
            ) {
                result.scores = basketballAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = basketballAdapter.adaptOverviewInfo(overview, options);
            }

            break;
        }

        case Sports.VOLLEYBALL:
        case Sports.ESPORTS_VOLLEYBALL:
        case Sports.BEACH_VOLLEYBALL: {
            if (
                overview &&
                (overview.__typename === 'VolleyballOverview' ||
                    overview.__typename === 'EVolleyballOverview')
            ) {
                defineCompetitorServer(overview.server, result);

                scores = volleyballAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = volleyballAdapter.adaptOverviewInfo(overview, options);

                break;
            }

            scores = volleyballAdapter.adaptFixtureScores(fixtureArgs, options);
            info = volleyballAdapter.adaptFixtureInfo(fixtureArgs, options);

            defineServerFromMeta(meta, result);

            break;
        }

        case Sports.ICE_HOCKEY: {
            if (overview && overview.__typename === 'IceHockeyOverview') {
                result.scores = iceHockeyAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = iceHockeyAdapter.adaptOverviewInfo(overview, options);

                break;
            }

            scores = iceHockeyAdapter.adaptFixtureScores(fixtureArgs, options);
            info = iceHockeyAdapter.adaptFixtureInfo(fixtureArgs, options);

            break;
        }

        case Sports.ESPORTS_LEAGUE_OF_LEGENDS_WILD_RIFT:
        case Sports.ESPORTS_KING_OF_GLORY: {
            if (
                overview &&
                (overview.__typename === 'KingOfGloryOverview' ||
                    overview.__typename === 'LOLWildriftOverview')
            ) {
                scores = mobileMobaAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = mobileMobaAdapter.adaptOverviewInfo(overview, options);
            }

            break;
        }

        case Sports.INDOOR_SOCCER: {
            if (overview && overview.__typename === 'IndoorSoccerOverview') {
                scores = indoorSoccerAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = indoorSoccerAdapter.adaptOverviewInfo(overview, options);
            }

            break;
        }

        case Sports.ESPORTS_ROCKET_LEAGUE: {
            scores = rocketLeagueAdapter.adaptFixtureScores(
                fixtureArgs,
                options
            );
            info = rocketLeagueAdapter.adaptFixtureInfo(fixtureArgs, options);

            break;
        }

        case Sports.ESPORTS_VALORANT: {
            scores = valorantAdapter.adaptFixtureScores(fixtureArgs, options);
            info = valorantAdapter.adaptFixtureInfo(fixtureArgs, options);

            break;
        }

        case Sports.ESPORTS_RAINBOW_SIX: {
            scores = rainbow6Adapter.adaptFixtureScores(fixtureArgs, options);
            info = rainbow6Adapter.adaptFixtureInfo(fixtureArgs, options);

            break;
        }

        case Sports.BASEBALL: {
            scores = baseballAdapter.adaptFixtureScores(fixtureArgs, options);
            info = baseballAdapter.adaptFixtureInfo(fixtureArgs, options);

            defineServerFromMeta(meta, result);

            break;
        }

        case Sports.BADMINTON: {
            scores = badmintonAdapter.adaptFixtureScores(fixtureArgs, options);
            info = badmintonAdapter.adaptFixtureInfo(fixtureArgs, options);

            defineServerFromMeta(meta, result);

            break;
        }

        case Sports.HANDBALL: {
            scores = footballFixtureAdapter.adaptFixtureScores(
                fixtureArgs,
                options
            );
            info = footballFixtureAdapter.adaptFixtureInfo(
                fixtureArgs,
                options
            );
            break;
        }

        case Sports.ESPORTS_CALL_OF_DUTY_WARZONE:
        case Sports.ESPORTS_FORTNITE: {
            scores = battleRoyalAdapter.adaptFixtureScores(
                fixtureArgs,
                options
            );
            info = battleRoyalAdapter.adaptFixtureInfo(fixtureArgs, options);

            break;
        }

        case Sports.ESPORTS_VOLTA_FOOTBALL: {
            scores = eVolaFootballAdapter.adaptFixtureScores(
                fixtureArgs,
                options
            );
            info = eVolaFootballAdapter.adaptFixtureInfo(fixtureArgs, options);

            break;
        }

        case Sports.ESPORTS_MORTAL_KOMBAT: {
            if (overview && overview.__typename === 'MortalKombatOverview') {
                scores = mortalKombatAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = mortalKombatAdapter.adaptOverviewInfo(overview, options);
                result.totalScore.home = overview.teams.home.score.toString();
                result.totalScore.away = overview.teams.away.score.toString();
            }

            break;
        }

        case Sports.ESPORTS_BRAWL_STARS: {
            if (overview && overview.__typename === 'BrawlStarsOverview') {
                scores = brawlStarsAdapter.adaptOverviewScores(
                    overview,
                    options
                );
                info = brawlStarsAdapter.adaptOverviewInfo(overview, options);
                result.totalScore.home = overview.teams.home.score.toString();
                result.totalScore.away = overview.teams.away.score.toString();
            }

            break;
        }

        default: {
            scores = adaptFixtureScores(homeAwayCompetitors);
        }
    }

    result = {
        scores,
        ...result,
        ...info,
    };

    return result;
}

const defineCompetitorServer = (
    serverSide: TeamHomeAway,
    result: ScoreBoard
): void => {
    result.competitors.forEach((competitor, index) => {
        // eslint-disable-next-line no-param-reassign
        competitor.isServer =
            serverSide ===
            (index === 0 ? TeamHomeAway.Home : TeamHomeAway.Away);
    });
};

const defineServerFromMeta = (
    meta: NonNullable<MatchBaseWithOptionalMeta['meta']>,
    result: ScoreBoard
): void => {
    const server = getMetaValueByName(meta, SportEventMetaKey.Server);

    if (server && server !== 'unknown') {
        defineCompetitorServer(
            server === 'home' ? TeamHomeAway.Home : TeamHomeAway.Away,
            result
        );
    }
};
