import React from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import type { PartialMatchMarkets } from 'types';

import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { MARKET_STUB, MOBILE_OVERVIEW_MARKETS_TYPE_ID } from './constants';
import OverviewMarket from './OverviewMarket';

interface Props {
    markets: PartialMatchMarkets;
    withName?: boolean;
    sportEventId: string;
    isResizable?: boolean;
}

const COUNT_MARKETS = 3;

const TopMarkets: React.FC<Props> = ({
    markets,
    withName,
    sportEventId,
    isResizable,
}) => {
    const { until } = useResponsive();
    const isMobile = until('sm');
    const t = useTranslations('betting-global.global');

    const marketsByDeviceType = isMobile
        ? markets.filter((item) =>
              MOBILE_OVERVIEW_MARKETS_TYPE_ID.includes(item.typeId)
          )
        : markets;

    const marketsList: PartialMatchMarkets = marketsByDeviceType.length
        ? marketsByDeviceType
        : [{ ...MARKET_STUB, name: t('Winner') }];

    return (
        <div className="flex w-full items-stretch" data-test="top-markets">
            {marketsList.slice(0, COUNT_MARKETS).map((market, marketIndex) => (
                <div
                    key={`${market.path}`}
                    className={cn(
                        'flex h-full w-full min-w-0 flex-1 flex-col',
                        {
                            'hidden sm:block': marketIndex === 1 && isResizable,
                            'hidden md:block': marketIndex === 2,
                            'sm:not-last:mr-2': isResizable,
                        }
                    )}
                >
                    <OverviewMarket
                        market={market}
                        withName={withName}
                        sportEventId={sportEventId}
                        isResizable={isResizable}
                    />
                </div>
            ))}
        </div>
    );
};

export default TopMarkets;
