import { FC, memo } from 'react';

import FavoriteButton from 'components/betting/FavoriteButton';
import { useSportEventId } from '../../SportEventProvider';

interface Props {
    text?: string | JSX.Element;
    subtext?: string | JSX.Element;
}

const Info: FC<Props> = ({ text, subtext }) => {
    const { sportEventId } = useSportEventId();

    return (
        <div className="flex h-12 items-center gap-2">
            <FavoriteButton sportEventId={sportEventId} />
            <div className=" text-sm text-grey-500">
                <div>{text}</div>
                <div>{subtext}</div>
            </div>
        </div>
    );
};

export default memo(Info);
