import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';
import { adaptFixtureScores, findAwayScore, makeMetaFinder } from './utils';

const { Bo, GameState, StateNumber } = SportEventMetaKey;
const { TYPE_OVERTIME } = ScoreTypes;

const findGameState = makeMetaFinder(GameState, StateNumber);

const rainbow6Adapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta }, { translate: { tSpecifier } }) {
        const currentMeta = findGameState(meta);
        const bo = getMetaValueByName(meta, Bo);

        if (!currentMeta)
            return {
                mainScoreType: ScoreType.Total,
                matchInfo: {
                    title: '',
                },
            };

        const isOvertime = currentMeta.name === TYPE_OVERTIME;

        return {
            matchInfo: {
                title: isOvertime
                    ? tSpecifier('overtimenr')
                    : `${tSpecifier('map')} ${currentMeta.value}`,
            },
            mainScoreType: ScoreType.Total,
            bo: bo ? Number(bo) : undefined,
        };
    },
    adaptFixtureScores({ competitors }, { isMatchListView }) {
        const scoreList = adaptFixtureScores(competitors, {
            scoreIteratee: (score) => {
                if (score.type === ScoreTypes.TYPE_ROUND_OVERTIME) {
                    return {
                        home: score.points,
                        away: findAwayScore(competitors[1], score.id).points,
                        title: `OT`,
                        type: ScoreType.ExtraInfo,
                    };
                }
            },
        });

        if (isMatchListView) {
            return scoreList
                .filter((score) => score.type !== ScoreType.ExtraInfo)
                .slice(-2);
        }

        return scoreList;
    },
};

export default rainbow6Adapter;
