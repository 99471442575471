import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';
import { adaptFixtureScores, getMessage } from './utils';

const { Time, Period, TimerRunning } = SportEventMetaKey;

const eVoltaFootballAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta }, { translate }) {
        const currentMatchPart = getMetaValueByName(meta, Period) as ScoreTypes;

        const time = Number(getMetaValueByName(meta, Time) || 0);

        const isTimerRunning = !!getMetaValueByName(meta, TimerRunning);

        return {
            mainScoreType: ScoreType.Total,
            timer: {
                isTimerRunning,
                time,
                isDecrement: true,
            },
            matchInfo: {
                title: getMessage(currentMatchPart, '', translate),
            },
        };
    },
    adaptFixtureScores({ competitors, meta }) {
        const currentMatchPart = getMetaValueByName(meta, Period) as ScoreTypes;

        const scoreList = adaptFixtureScores(competitors, {
            activeScoreType: {
                type: currentMatchPart,
                number: 0,
            },
        });

        return scoreList;
    },
};

export default eVoltaFootballAdapter;
