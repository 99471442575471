import dynamic from 'next/dynamic';

const Stream = dynamic(
    () => import(/* webpackChunkName: "Stream" */ './Stream'),
    { ssr: false }
);

export default Stream;

export { default as useBroadcastHelper } from './useBroadcastHelper';
