import { compact, filter, find, groupBy } from 'lodash';

import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import type { ETennis, ETennisSet, Tennis, TennisSet } from 'types/gql.bet';
import { TennisGamePoint, TennisSetStatus } from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import {
    FixtureScoreboardAdapter,
    ScoreboardAdapter,
    ScoreBoardScore,
    ScoreType,
} from '../types';
import { findAwayScore, isZeroBasedFixture } from './utils';

const CURRENT_PART_COLOR = 'text-primary-white';

const colors = {
    home: CURRENT_PART_COLOR,
    away: CURRENT_PART_COLOR,
};

const POINTS = {
    [TennisGamePoint.Point0]: '0',
    [TennisGamePoint.Point15]: '15',
    [TennisGamePoint.Point30]: '30',
    [TennisGamePoint.Point40]: '40',
    [TennisGamePoint.PointAbove]: 'A',
};

type SetType = ETennisSet | TennisSet;

const { Set } = SportEventMetaKey;
const { TYPE_SET, TYPE_TIE_BREAK, TYPE_GAME_POINTS, TYPE_TOTAL } = ScoreTypes;

const tennisAdapter: ScoreboardAdapter<Tennis | ETennis> &
    FixtureScoreboardAdapter = {
    adaptFixtureScores(
        { meta, competitors },
        { isMatchListView, translate: { tSpecifier } }
    ) {
        const isTieBreak = meta.find(
            (metaItem) => metaItem.value === TYPE_TIE_BREAK
        );
        const currentSetNumber = Number(getMetaValueByName(meta, Set) || '0');

        const [home] = competitors;

        const isZeroBased = isZeroBasedFixture(home.score, TYPE_SET);

        const {
            sets = [],
            tb = [],
            gamePoints = [],
            totals = [],
        } = groupBy(home.score, (score) => {
            if (score.type === TYPE_SET) return 'sets';

            if (score.type === TYPE_GAME_POINTS) return 'gamePoints';

            if (score.type === TYPE_TOTAL) return 'totals';

            return 'tb';
        });

        const setList = sets
            .map((score) => ({
                home: score.points,
                away: findAwayScore(competitors[1], score.id).points,
                title: String(isZeroBased ? score.number + 1 : score.number),
                type:
                    currentSetNumber === score.number
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            }))
            .sort((a, b) => +a.title - +b.title);

        const tieBreakList = tb
            .map((score) => ({
                home: score.points,
                away: findAwayScore(competitors[1], score.id).points,
                title: `tb${isZeroBased ? score.number + 1 : score.number}`,
                type:
                    isTieBreak && !isMatchListView
                        ? ScoreType.MainVisibleScores
                        : ScoreType.Other,
            }))
            .sort((a, b) => +a.title - +b.title);

        const gamePoint = gamePoints[0]
            ? {
                  home: gamePoints[0].points,
                  away: findAwayScore(competitors[1], gamePoints[0]?.id).points,
                  title: 'P',
                  type: ScoreType.CurrentPart,
              }
            : null;

        let setsWithTieBreak: ScoreBoardScore[] = [...setList];

        if (tieBreakList.length) {
            setsWithTieBreak = setList.map((score) => {
                if (tieBreakList[0].title.includes(score.title)) {
                    return {
                        ...score,
                        home: `${score.home} (${tieBreakList[0].home})`,
                        away: `${score.away} (${tieBreakList[0].away})`,
                    };
                }

                return score;
            });
        }

        if (isMatchListView) {
            return [...setsWithTieBreak.slice(-2), gamePoint].filter(
                (i) => i
            ) as ScoreBoardScore[];
        }

        const setsColumn = {
            home: totals[0]?.points || '0',
            away: findAwayScore(competitors[1], totals[0]?.id)?.points,
            title: tSpecifier('setnr'),
            type: ScoreType.Total,
        };

        const mainVisibleGamePoints =
            !isTieBreak && gamePoint
                ? [{ ...gamePoint, type: ScoreType.MainVisibleScores }]
                : [];

        const scoresForRender = filter(
            [
                ...setsWithTieBreak,
                gamePoint,
                ...mainVisibleGamePoints,
                setsColumn,
            ],
            (i) => !!i
        ) as ScoreBoardScore[];

        if (tieBreakList[0]?.type === ScoreType.MainVisibleScores) {
            scoresForRender.push(tieBreakList[0]);
        }

        return scoresForRender;
    },
    adaptFixtureInfo({ meta, competitors }, { translate: { tSpecifier } }) {
        let title = '';
        let subTitle = '';
        const currentSet = getMetaValueByName(meta, Set);
        const tieBreak = meta.find((m) => m.value === TYPE_TIE_BREAK);
        const isZeroBased = isZeroBasedFixture(competitors[0]?.score, TYPE_SET);

        if (tieBreak) {
            subTitle = tSpecifier('tieBreak');
        }

        title = currentSet
            ? `${tSpecifier('setnr')} ${
                  isZeroBased ? +currentSet + 1 : currentSet
              }`
            : '';

        return {
            mainScoreType: ScoreType.MainVisibleScores,
            matchInfo: {
                title,
                subTitle,
            },
        };
    },
    adaptOverviewScores(
        { sets, currentSet, teams },
        { isMatchListView, translate: { tSpecifier } }
    ) {
        if (!sets?.length) return [];

        const currentSetNumber = currentSet === 0 ? sets.length : currentSet;

        const homeGamePoint = POINTS[teams.home.gamePoint.gamePoint];
        const awayGamePoint = POINTS[teams.away.gamePoint.gamePoint];

        const gamePoints = {
            title: 'P',
            home: homeGamePoint,
            away: awayGamePoint,
            type: ScoreType.CurrentPart,
        };

        const targetSets: ScoreBoardScore[] = sets.map((set) => {
            const scores = getScoreBySetStateForSportLine(
                set,
                currentSetNumber
            );

            return {
                title: String(set.number),
                ...scores,
            };
        });

        if (isMatchListView) {
            return [...targetSets.slice(-2), gamePoints];
        }

        const mainScore: ScoreBoardScore[] = compact(
            sets.map((set) => {
                const scores = getMainScore(set, currentSetNumber);

                if (!scores) return;

                return {
                    title: String(set.number),
                    ...scores,
                };
            })
        );

        if (!mainScore.length) {
            mainScore.push({
                title: '',
                home: `${homeGamePoint}`,
                away: `${awayGamePoint}`,
                type: ScoreType.MainVisibleScores,
            });
        }

        const setsPoint = {
            title: tSpecifier('setnr'),
            home: `${teams.home.set.score}`,
            away: `${teams.away.set.score}`,
            type: ScoreType.ExtraInfo,
        };

        return [...targetSets, ...mainScore, gamePoints, setsPoint];
    },
    adaptOverviewInfo(
        { sets, currentSet: numberOfSet },
        { translate: { tSpecifier } }
    ) {
        if (!sets?.length)
            return {
                matchInfo: { title: '' },
                mainScoreType: ScoreType.MainVisibleScores,
            };

        const currentSetNumber = numberOfSet === 0 ? sets?.length : numberOfSet;
        const currentSet = find<SetType>(
            sets,
            (set) => set.number === currentSetNumber
        );

        const isTieBreak = currentSet?.state === TennisSetStatus.TieBreak;

        return {
            mainScoreType: ScoreType.MainVisibleScores,
            matchInfo: {
                title: `${currentSetNumber} ${tSpecifier('setnr')}`,
                subTitle: isTieBreak ? tSpecifier('tieBreak') : '',
            },
            colors,
        };
    },
};

export function getMainScore(
    { tieBreakScore, number, state }: SetType,
    currentSetNumber: number
): { home: string; away: string; type: ScoreType } | null {
    const isTieBreak = state === TennisSetStatus.TieBreak;
    const isCurrentSet = number === currentSetNumber;

    if (isCurrentSet && isTieBreak) {
        return {
            home: `${tieBreakScore.home}`,
            away: `${tieBreakScore.away}`,
            type: ScoreType.MainVisibleScores,
        };
    }

    return null;
}

export function getScoreBySetStateForSportLine(
    { gameScore, tieBreakScore, number, state }: SetType,
    currentSetNumber: number
): { home: string; away: string; type: ScoreType } {
    const isTieBreak = state === TennisSetStatus.TieBreak;
    const isCurrentSet = number === currentSetNumber;

    return {
        home: `${gameScore.home} ${
            isTieBreak ? `(${tieBreakScore.home})` : ''
        }`,
        away: `${gameScore.away} ${
            isTieBreak ? `(${tieBreakScore.away})` : ''
        }`,
        type: isCurrentSet ? ScoreType.CurrentPart : ScoreType.Other,
    };
}

export default tennisAdapter;
