import cn from 'classnames';
import { capitalize, map } from 'lodash';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { Csgo, CsgoMap, CsgoMatchFormat, CsgoTeamSide } from 'types/gql.bet';
import {
    ScoreboardAdapter,
    ScoreBoardColors,
    ScoreType,
    TranslateScoreboard,
} from '../types';

type Rounds = Partial<Record<CsgoMatchFormat, number>>;

export const COLORS_BY_SIDE = {
    [CsgoTeamSide.T]: cn(
        '!border-primary-orange-toxic bg-primary-orange-toxic text-primary-orange-toxic'
    ),
    [CsgoTeamSide.Ct]: cn(
        '!border-turquoise-500 bg-primary-blue-toxic text-turquoise-500'
    ),
    [CsgoTeamSide.Unknown]: cn('bg-primary-white text-primary-white'),
};

const OVERTIME_ROUNDS: Rounds = {
    [CsgoMatchFormat.Mr15]: 6,
    [CsgoMatchFormat.AwayAdvMr15]: 6,
    [CsgoMatchFormat.HomeAdvMr15]: 6,
    [CsgoMatchFormat.Mr15WithDraw]: 0,
    [CsgoMatchFormat.Mr12Ot3]: 3,
    [CsgoMatchFormat.Mr8Ot1]: 1,
};

const BASE_TOTAL_ROUNDS: Rounds = {
    [CsgoMatchFormat.Mr15]: 30,
    [CsgoMatchFormat.AwayAdvMr15]: 30,
    [CsgoMatchFormat.HomeAdvMr15]: 30,
    [CsgoMatchFormat.Mr15WithDraw]: 30,
    [CsgoMatchFormat.Mr12Ot3]: 24,
    [CsgoMatchFormat.Mr8Ot1]: 16,
};

const csgoAdapter: ScoreboardAdapter<Csgo> = {
    adaptOverviewScores(overview, { translate, isMatchListView }) {
        const { currentMap: currentMapNumber, maps } = overview;

        const scoreList = map(maps, (m) => {
            const mapShortName = getMapName({
                mapName: m.map.toLowerCase(),
                format: 'short',
                currentMapNumber: m.number,
                translate,
            });

            return {
                title: mapShortName,
                home: `${m.home.score}`,
                away: `${m.away.score}`,
                type:
                    m.number === currentMapNumber
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            };
        });

        return isMatchListView ? scoreList.slice(-2) : scoreList;
    },
    adaptOverviewInfo(overview, { translate }) {
        const {
            bomb,
            currentMap: currentMapNumber,
            currentRound,
            mapName,
            bestOf,
            timer,
            teams,
            matchFormat: roundsFormat,
        } = overview;

        const formattedMapName = getMapName({
            mapName: mapName.toLowerCase(),
            format: 'long',
            currentMapNumber,
            translate,
        });

        const matchFormat = bestOf ? `BO${bestOf}:` : '';

        const subTitle = getSubTitleInfo({
            currentRound,
            roundsFormat,
            translate,
        });
        const title = `${matchFormat} ${formattedMapName}`.trim();

        const { isPlanted = false } = bomb || {};

        const colors: ScoreBoardColors = {
            home: COLORS_BY_SIDE[teams.home.currentSide],
            away: COLORS_BY_SIDE[teams.away.currentSide],
        };

        return {
            matchInfo: { title, subTitle },
            mainScoreType: ScoreType.CurrentPart,
            headerIcon: {
                icon: (
                    <PackIcon
                        id="cs-bomb"
                        pack={IconsPack.SpriteIcons}
                        className={cn(
                            isPlanted
                                ? 'animate-flashing fill-red-500'
                                : 'fill-grey-500'
                        )}
                    />
                ),
            },
            // TODO: update with the latest version from current gg
            timer: !isPlanted
                ? {
                      time: timer.time,
                      isTimerRunning: timer.isActive,
                      isDecrement: true,
                  }
                : undefined,
            bo: bestOf,
            colors,
        };
    },
};

const CS_MAP_NAME_MAPPING: {
    [key: string]: {
        short: string;
        long: string;
    };
} = {
    de_dust2: {
        short: 'D2',
        long: 'Dust 2',
    },
    de_inferno: {
        short: 'Inf',
        long: 'Inferno',
    },
    de_overpass: {
        short: 'Ovp',
        long: 'Overpass',
    },
    de_mirage: {
        short: 'Mrg',
        long: 'Mirage',
    },
    de_nuke: {
        short: 'Nuk',
        long: 'Nuke',
    },
    de_vertigo: {
        short: 'Vrt',
        long: 'Vertigo',
    },
    de_ancient: {
        short: 'Anc',
        long: 'Ancient',
    },
    de_train: {
        short: 'Trn',
        long: 'Train',
    },
    de_cache: {
        short: 'Cch',
        long: 'Cache',
    },
    de_cobblestone: {
        short: 'Cbl',
        long: 'Cobblestone',
    },
    de_anubis: {
        short: 'Anb',
        long: 'Anubis',
    },
};

export function getMapName({
    mapName,
    currentMapNumber,
    format,
    translate,
}: {
    mapName: string | undefined;
    format: 'short' | 'long';
    currentMapNumber?: number;
    translate: TranslateScoreboard;
}): string {
    const fallbackMapname = `${currentMapNumber || ''}`;

    if (!mapName) return fallbackMapname;

    if (mapName === CsgoMap.Unknown.toLowerCase() && format === 'long') {
        return `${translate.tSpecifier('map')} ${currentMapNumber}` || '';
    }

    return CS_MAP_NAME_MAPPING[mapName]?.[format] || fallbackMapname;
}

export function getRounds({
    currentRound,
    totalRounds,
    overtimeRounds = 0,
}: {
    currentRound: number;
    totalRounds: number;
    overtimeRounds?: number;
}): number {
    if (totalRounds >= currentRound) return totalRounds;

    return totalRounds + overtimeRounds;
}

export const getSubTitleInfo = ({
    currentRound,
    roundsFormat,
    translate,
}: {
    currentRound: number;
    roundsFormat: CsgoMatchFormat;
    translate: TranslateScoreboard;
}): string | undefined => {
    const totalRounds = BASE_TOTAL_ROUNDS[roundsFormat];
    const overtimeRounds = OVERTIME_ROUNDS[roundsFormat];

    if (!totalRounds) return;

    const { tSpecifier } = translate;

    const isOvertime = currentRound > totalRounds;

    const round = `R: ${currentRound}/${getRounds({
        currentRound,
        totalRounds,
        overtimeRounds,
    })}`;

    const overtimeText =
        overtimeRounds && isOvertime
            ? `${capitalize(tSpecifier('overtimenr'))}: ${
                  (currentRound - totalRounds) % overtimeRounds
              }/${overtimeRounds}`
            : '';

    return overtimeText || round;
};

export default csgoAdapter;
