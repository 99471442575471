import React, { ReactElement } from 'react';
import cn from 'classnames';

import Image from 'components/Image';

interface Props {
    icon?: string;
    title?: string;
    message?: string;
    className?: string;
    bottomChild?: ReactElement;
}

const Empty: React.FC<Props> = ({
    icon,
    title,
    message,
    className,
    bottomChild,
}) => {
    return (
        <div
            className={cn(
                'mx-auto flex flex-col items-center px-6 py-12 text-center',
                className
            )}
            data-test="empty"
        >
            {icon && (
                <div className="mb-8 h-[100px] w-[100px]">
                    <Image
                        src={icon}
                        width={100}
                        height={100}
                        unoptimized={true}
                        alt=""
                    />
                </div>
            )}
            {title && (
                <div className="mb-2 text-primary-white typo-lg-2">{title}</div>
            )}
            {message && (
                <div
                    className="w-full break-words text-grey-500 typo-base"
                    data-test="empty__message"
                >
                    {message}
                </div>
            )}
            {bottomChild}
        </div>
    );
};

export default React.memo(Empty);
