import { AmericanFootball } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreBoardScore, ScoreType } from '../types';

const PERIODS_COUNT = 4 as const;

const americanFootballAdapter: ScoreboardAdapter<AmericanFootball> = {
    adaptOverviewInfo({ currentPeriod, timer }, { translate: { tSpecifier } }) {
        const isPeriod = currentPeriod <= PERIODS_COUNT;

        let title = '';

        if (currentPeriod) {
            title = isPeriod
                ? `P: ${currentPeriod}/${PERIODS_COUNT}`
                : `${currentPeriod - PERIODS_COUNT} ${tSpecifier(
                      'overtimenr'
                  )}`;
        }

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.Total,
            timer: {
                isTimerRunning: timer.isActive,
                time: timer.time,
                isDecrement: true,
            },
        };
    },
    adaptOverviewScores({ currentPeriod, periods }, { isMatchListView }) {
        if (!periods) return [];

        const scores: ScoreBoardScore[] = periods.map(
            ({ number, totalPointsHome, totalPointsAway }) => {
                const isPeriod = number <= PERIODS_COUNT;

                return {
                    title: isPeriod
                        ? number.toString()
                        : `OVT ${number - PERIODS_COUNT}`,
                    home: totalPointsHome.toString(),
                    away: totalPointsAway.toString(),
                    type:
                        number === currentPeriod
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                };
            }
        );

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
};

export default americanFootballAdapter;
