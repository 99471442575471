import type { SpecifiersTranslate } from 'translations/types';
import { SportEventPart } from 'translations/types';

import { ScoreTypes } from 'app-constants';
import { Overview } from 'types';
import { Pair } from 'types/api.bet';
import {
    Competitor,
    CompetitorScore,
    CompetitorType,
    MatchBase,
} from 'types/gql.bet';

export interface Meta extends Pair {
    id?: string;
}

export interface ScoreboardTimer {
    time: number | undefined;
    isDecrement?: boolean;
    isTimeInMinutes?: boolean;
    isTimeAbsolute?: boolean;
    isTimerRunning?: boolean;
}

export interface ScoreBoard {
    competitors: ScoreBoardCompetitor[];
    matchInfo: ScoreBoardMatchInfo;
    totalScore: ScoreBoardScore;
    mainScoreType: ScoreType;
    scores?: ScoreBoardScore[];
    headerIcon?: ScoreBoardHeaderIcon;
    bo?: number;
    timer?: ScoreboardTimer;
    colors?: ScoreBoardColors;
}

export type ScoreboardInfo = Pick<
    ScoreBoard,
    'bo' | 'headerIcon' | 'timer' | 'matchInfo' | 'colors' | 'mainScoreType'
>;

export interface ScoreBoardCompetitor {
    id: string;
    logo: string;
    name: string;
    type: CompetitorType;
    isServer: boolean;
    slug?: string;
    color?: string;
}

export enum ScoreType {
    Total = 'total',
    CurrentPart = 'currentPart',
    ExtraInfo = 'extraInfo',
    Other = 'other',
    MainVisibleScores = 'mainVisibleScores',
    HiddenTotal = 'hiddenTotal',
}

export interface ScoreBoardScore {
    title: string | JSX.Element;
    away: string;
    home: string;
    type: ScoreType;
}

export interface ScoreBoardMatchInfo {
    title: string | JSX.Element;
    subTitle?: string | JSX.Element;
}

export interface ScoreBoardHeaderIcon {
    text?: string;
    icon?: JSX.Element;
}

export interface ScoreBoardColors {
    home: string;
    away: string;
}

export type TranslateScoreboard = {
    tSpecifier: SpecifiersTranslate;
    tSportEvent?: SportEventPart;
};

type Options = {
    isMatchListView: boolean;
    isMobile?: boolean;
    translate: TranslateScoreboard;
};

export interface ScoreboardAdapter<T extends Overview>
    extends Partial<FixtureScoreboardAdapter> {
    // TODO: use "satisfies" after TS updated to 4.9+
    adaptOverviewScores: (overview: T, options: Options) => ScoreBoardScore[];
    adaptOverviewInfo: (overview: T, options: Options) => ScoreboardInfo;
}

export interface AdaptFixtureArgs {
    competitors: Competitor[];
    meta: NonNullable<MatchBase['meta']>;
}

export interface FixtureScoreboardAdapter {
    adaptFixtureScores: (
        args: AdaptFixtureArgs,
        options: Options
    ) => ScoreBoardScore[];
    adaptFixtureInfo: (
        args: AdaptFixtureArgs,
        options: Options
    ) => ScoreboardInfo;
}

export interface AdaptFixtureScoreOptions {
    scoreIteratee?: (
        score: CompetitorScore,
        isZeroBased?: boolean
    ) => ScoreBoardScore | false | void;
    activeScoreType?: { type: ScoreTypes; number: number };
}

export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;
