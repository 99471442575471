import type { FC } from 'react';
import { memo, useRef } from 'react';
import type { Overview } from 'types';

import { ACTIVE_MARKET_STATUSES } from 'app-constants';
import { useOnUpdateSportEvent } from 'gql/betting/subscriptions/onUpdateSportEvent.bet';
import { withClientRender } from 'hocs';
import { useOverviewSubscription } from 'hooks';
import SubscriptionRowDebugInfo from './SubscriptionRowDebugInfo';
import useSportEventInViewSubscription from './useSportEventInViewSubscription';

interface Props {
    sportEventId: string;
    version: string;
}

interface PropsWithChildren {
    sportEventId: string;
    version: string;
    children: (overview?: Overview) => JSX.Element;
    sportId: string;
}

const SubscriptionSportEvent: FC<Props> = ({ sportEventId, version }) => {
    const versionRef = useRef<string>(version);

    useOnUpdateSportEvent({
        variables: {
            sportEventId,
            marketStatuses: ACTIVE_MARKET_STATUSES,
            isTopMarkets: true,
            version: versionRef.current,
        },
    });

    return null;
};

const SportEventInViewSubscription: FC<PropsWithChildren> = ({
    sportEventId,
    version,
    children,
    sportId,
}) => {
    const { isSubscribed, inViewRef } = useSportEventInViewSubscription();

    const { overview } = useOverviewSubscription({
        sportEventId,
        version,
        sportId,
        skip: !isSubscribed,
    });

    return (
        <div
            ref={inViewRef}
            className="relative h-full w-full"
            data-test="sport-event-in-view-subscription"
        >
            {isSubscribed && (
                <SubscriptionSportEvent
                    sportEventId={sportEventId}
                    version={version}
                />
            )}
            <SubscriptionRowDebugInfo
                sportEventId={sportEventId}
                isSubscribed={isSubscribed}
            />
            {children(overview)}
        </div>
    );
};

export default memo(
    withClientRender(SportEventInViewSubscription, ({ children }) => {
        return children(undefined);
    })
);
