export enum HeaderType {
    Sport = 'sport',
    Tournament = 'tournament',
    None = 'none',
}

export enum OriginType {
    Carousel = 'carousel',
    List = 'list',
    Default = 'default',
}
