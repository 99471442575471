import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';

interface SportEventId {
    sportEventId: string;
}

const SportEventContext = createContext<SportEventId>({
    sportEventId: '',
});

type Props = PropsWithChildren<SportEventId>;

const SportEventProvider: FC<Props> = ({ children, sportEventId }) => {
    const value = useMemo(() => ({ sportEventId }), [sportEventId]);

    return (
        <SportEventContext.Provider value={value}>
            {children}
        </SportEventContext.Provider>
    );
};

export const useSportEventId = (): SportEventId =>
    useContext(SportEventContext);

export default SportEventProvider;
