import React from 'react';
import cn from 'classnames';

import FavoriteButton from 'components/betting/FavoriteButton';
import ClientRender from 'components/ClientRender';
import OpacityWrapper from 'components/OpacityWrapper';
import PackIcon, { IconsPack } from 'components/PackIcon';

interface Props {
    sportEventId: string;
    isAvailableStream: boolean;
    day?: string;
    time?: string;
}
const PrematchFixture: React.FC<Props> = ({
    sportEventId,
    isAvailableStream,
    day,
    time,
}) => {
    return (
        <>
            <FavoriteButton sportEventId={sportEventId} />

            <OpacityWrapper>
                {(opacityClassName) => (
                    <div
                        className={cn(
                            'text-sm text-grey-500',
                            opacityClassName
                        )}
                    >
                        <div>{time}</div>
                        <div>{day}</div>
                    </div>
                )}
            </OpacityWrapper>

            {isAvailableStream && (
                <ClientRender>
                    <PackIcon
                        id="play-icon"
                        pack={IconsPack.SpriteIcons}
                        className="fill-red-700"
                    />
                </ClientRender>
            )}
        </>
    );
};

export default React.memo(PrematchFixture);
