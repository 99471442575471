import { map } from 'lodash';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import { Dota2, Dota2TeamSide } from 'types/gql.bet';
import {
    ScoreboardAdapter,
    ScoreBoardColors,
    ScoreBoardScore,
    ScoreType,
} from '../types';

export const COLORS_BY_SIDE = {
    [Dota2TeamSide.Radiant]: 'bg-green-500 border-green-500 text-green-500',
    [Dota2TeamSide.Dire]: 'bg-red-500 border-red-500 text-red-500',
    [Dota2TeamSide.Unknown]: 'bg-primary-white text-primary-white',
};

const dota2Adapter: ScoreboardAdapter<Dota2> = {
    adaptOverviewScores(overview, { isMatchListView }) {
        const {
            currentMap: currentMapNumber,
            maps,
            currentGoldLead,
        } = overview;

        const scores: ScoreBoardScore[] = map(maps, (m) => {
            return {
                title: `${m.number}`,
                home: `${m.teams.home.kills}`,
                away: `${m.teams.away.kills}`,
                type:
                    m.number === currentMapNumber
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            };
        });

        if (isMatchListView) {
            return scores.slice(-2);
        }

        if (currentGoldLead) {
            scores.unshift({
                title: (
                    <PackIcon
                        id="gold"
                        pack={IconsPack.SpriteIcons}
                        size={SvgIconSize.WH_4}
                        className="fill-grey-500"
                    />
                ),
                home: `${currentGoldLead / 1000}k`,
                away: `${currentGoldLead / -1000}k`,
                type: ScoreType.ExtraInfo,
            });
        }

        return scores;
    },
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { currentMap: currentMapNumber, timer, maps, bestOf } = overview;

        const currentMap = maps?.find((m) => m.number === currentMapNumber);

        const title = `${bestOf ? `BO${bestOf}: ` : ''}${tSpecifier(
            'map'
        )} ${currentMapNumber}`;

        const home: Dota2TeamSide =
            currentMap?.teams.home.side || Dota2TeamSide.Unknown;

        const away: Dota2TeamSide =
            currentMap?.teams.away.side || Dota2TeamSide.Unknown;

        const colors: ScoreBoardColors = {
            home: COLORS_BY_SIDE[home],
            away: COLORS_BY_SIDE[away],
        };

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.CurrentPart,
            // TODO: update with the latest version from current gg
            timer: {
                time: timer.time,
                isTimerRunning: timer.isActive,
                isDecrement: false,
            },
            bo: bestOf,
            colors,
        };
    },
};

export default dota2Adapter;
