import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import {
    EVolleyball,
    EVolleyballSetType,
    Volleyball,
    VolleyballSetType,
} from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import {
    FixtureScoreboardAdapter,
    ScoreboardAdapter,
    ScoreBoardScore,
    ScoreType,
} from '../types';
import { adaptFixtureScores, findAwayScore, isZeroBasedFixture } from './utils';

type Sets = Volleyball['sets'] | EVolleyball['sets'];

const { TYPE_SET } = ScoreTypes;
const { GoldenSet, Set } = SportEventMetaKey;

const volleyballAdapter: ScoreboardAdapter<Volleyball | EVolleyball> &
    FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta, competitors }, { translate: { tSpecifier } }) {
        let title = '';

        const goldenSet = getMetaValueByName(meta, GoldenSet);

        if (goldenSet) {
            title = 'GS';
        } else {
            const currentSet = getMetaValueByName(meta, Set);

            if (!currentSet) {
                return {
                    mainScoreType: ScoreType.Total,
                    matchInfo: {
                        title,
                    },
                };
            }

            const isZeroBased = isZeroBasedFixture(
                competitors[0].score,
                TYPE_SET
            );

            const currentSetTitle = isZeroBased ? +currentSet + 1 : +currentSet;

            title = `${currentSetTitle} ${tSpecifier('setnr')}`;
        }

        return {
            mainScoreType: ScoreType.Total,
            matchInfo: {
                title,
            },
        };
    },
    adaptFixtureScores({ competitors, meta }, { isMatchListView }) {
        const [home] = competitors;
        const goldenSet = getMetaValueByName(meta, GoldenSet);
        const activeSet = getMetaValueByName(meta, Set);

        const isZeroBased = isZeroBasedFixture(home.score, TYPE_SET);

        const scoreList = adaptFixtureScores(competitors, {
            scoreIteratee: (score) => {
                const titleScore = isZeroBased
                    ? `${score.number + 1}`
                    : String(score.number);

                return {
                    home: score.points,
                    away: findAwayScore(competitors[1], score.id).points,
                    title:
                        goldenSet && +goldenSet === score.number
                            ? 'GS'
                            : titleScore,
                    type:
                        Number(activeSet) === score.number
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                };
            },
        });

        if (isMatchListView) {
            return scoreList.slice(-3);
        }

        return scoreList;
    },
    adaptOverviewInfo({ currentSet, sets }, { translate: { tSpecifier } }) {
        return {
            mainScoreType: ScoreType.Total,
            matchInfo: {
                title: isGoldenSet(sets, currentSet)
                    ? 'GS'
                    : `${currentSet} ${tSpecifier('setnr')}`,
            },
        };
    },
    adaptOverviewScores({ sets, currentSet }, { isMatchListView }) {
        if (!sets?.length) return [];

        const scoreList: ScoreBoardScore[] = sets.map(
            ({ number, totalPointsHome, totalPointsAway, type }) => ({
                title: type === VolleyballSetType.Regular ? `${number}` : 'GS',
                home: `${totalPointsHome}`,
                away: `${totalPointsAway}`,
                type:
                    currentSet === number
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            })
        );

        return isMatchListView ? scoreList.slice(-3) : scoreList;
    },
};

const isGoldenSet = (sets: Sets, currentSet: number): boolean => {
    const isVolleyballSets = (setsList: Sets): setsList is Volleyball['sets'] =>
        setsList?.[0].__typename === 'VolleyballOverviewSet';

    return isVolleyballSets(sets)
        ? sets?.find(({ number }) => number === currentSet)?.type ===
              VolleyballSetType.Golden
        : sets?.find(({ number }) => number === currentSet)?.type ===
              EVolleyballSetType.Golden;
};

export default volleyballAdapter;
