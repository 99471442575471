import { FC, memo } from 'react';
import cn from 'classnames';

type ScoreValue = number | string | undefined;

interface Props {
    homeScore: ScoreValue;
    awayScore: ScoreValue;
    header: string | JSX.Element | number | undefined;
    isTotal?: boolean;
    homeClassName?: string;
    awayClassName?: string;
    isActivePart?: boolean;
}

const ScoreItem: FC<Props> = ({
    homeScore = 0,
    awayScore = 0,
    header,
    isTotal,
    awayClassName,
    homeClassName,
    isActivePart,
}) => {
    const wrapperStyle = cn(
        ' flex w-8 flex-col gap-4 typo-sm-1',
        isActivePart || isTotal ? 'text-primary-white' : 'text-grey-500'
    );

    const pointWrapperStyle = cn(
        'flex h-10 w-full items-center justify-center '
    );

    const pointStyle = cn(
        'flex h-6 w-full items-center justify-center rounded-[8px] bg-transparent',
        {
            '!bg-primary-black text-primary-white typo-md-2': isTotal,
        }
    );

    return (
        <div className={wrapperStyle}>
            <div
                className="flex h-12 shrink-0 items-center justify-center whitespace-nowrap"
                data-test="overview-score-item__header"
            >
                {header}
            </div>
            <div className="flex h-full items-center justify-center">
                <div className="flex w-full flex-col items-center gap-4">
                    <div className={pointWrapperStyle}>
                        <span className={cn(pointStyle, homeClassName)}>
                            {homeScore}
                        </span>
                    </div>
                    <div className={pointWrapperStyle}>
                        <span
                            className={cn(pointStyle, awayClassName)}
                            data-test={isTotal && 'overview-score'}
                        >
                            {awayScore}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ScoreItem);
