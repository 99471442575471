import cn from 'classnames';
import { find, includes } from 'lodash';

import {
    KingOfGlory,
    LolWildrift,
    TeamHomeAway,
    WinnerHomeAway,
} from 'types/gql.bet';
import { Flatten, ScoreboardAdapter, ScoreType } from '../types';

const colors = {
    home: cn('border-turquoise-500 bg-turquoise-500'),
    away: cn('border-red-500 bg-red-500'),
};

type MapsTypes<T extends { maps?: KingOfGlory['maps'] | LolWildrift['maps'] }> =
    NonNullable<Flatten<T['maps']>>;

const firstBlood = [WinnerHomeAway.Home, WinnerHomeAway.Away];

const mobileMobaAdapter: ScoreboardAdapter<KingOfGlory | LolWildrift> = {
    adaptOverviewScores(overview, { isMatchListView }) {
        const { currentMap, maps } = overview;

        if (!maps?.length) return [];

        const scores = maps.map(({ number, winner, homeKills, awayKills }) => {
            return {
                title: `${number}`,
                home: getScore({
                    winner,
                    side: TeamHomeAway.Home,
                    kills: homeKills,
                }),
                away: getScore({
                    winner,
                    side: TeamHomeAway.Away,
                    kills: awayKills,
                }),
                type:
                    number === currentMap
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            };
        });

        const currentFirstBlood = find<MapsTypes<KingOfGlory | LolWildrift>>(
            maps,
            ({ number }) => number === currentMap
        )?.firstBlood;
        const isFirstBlood = includes(firstBlood, currentFirstBlood);

        if (isFirstBlood && !isMatchListView) {
            scores.unshift({
                title: `FB`,
                home: currentFirstBlood === WinnerHomeAway.Home ? '+' : '',
                away: currentFirstBlood === WinnerHomeAway.Away ? '+' : '',
                type: ScoreType.ExtraInfo,
            });
        }

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { currentMap, timer, bestOf } = overview;

        const title = `BO${bestOf}: ${tSpecifier('map')} ${currentMap}`;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.CurrentPart,
            timer: {
                time: timer.time,
                isTimerRunning: timer.isActive,
                isDecrement: false,
            },
            bo: bestOf,
            colors,
        };
    },
};

export const getScore = ({
    winner,
    side,
    kills,
}: {
    winner: TeamHomeAway;
    side: TeamHomeAway;
    kills: number;
}): string => {
    if (winner === TeamHomeAway.Unknown) {
        return `${kills}`;
    }

    return side === winner ? 'W' : 'L';
};

export default mobileMobaAdapter;
