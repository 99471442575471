import React from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import type { PartialMatchMarket } from 'types';

import OddButton from 'components/betting/OddButton';
import PackIcon, { IconsPack } from 'components/PackIcon';
import HorizontalScroll from 'components/utilities/HorizontalScroll';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { idPathParser } from 'utils';

const ROW_ODDS_COUNT = 5 as const;
const MIN_MOBILE_ODDS_IN_ROW = 3 as const;

interface Props {
    sportEventId: string;
    market: PartialMatchMarket;
    isResizable: boolean;
}

const MultiCompetitorsMarket: React.FC<Props> = ({
    market,
    sportEventId,
    isResizable,
}) => {
    const t = useTranslations('betting-global.global');
    const { until } = useResponsive();
    const isSmall = until('sm');
    const odds = market?.odds;

    if (!odds?.length) return null;

    const slicedOdds = isSmall ? odds : odds.slice(0, 10);
    const oddsLength = slicedOdds.length;

    const oddsJsx = slicedOdds.map((odd) => (
        <OddButton
            key={idPathParser.join('Odd', {
                oddId: odd.id,
                marketId: market.id,
                sportEventId,
            })}
            classNames={cn('mr-0 !h-12', {
                'min-w-[120px]': isSmall,
            })}
            odd={odd}
            market={market}
            isResizable={isResizable}
            gap={2}
        />
    ));

    return (
        <div className="p-4 text-primary-white">
            <div className="mb-4 flex items-center justify-between">
                <div className="typo-sm-1">{market.name}</div>
                <div className="flex items-center">
                    <div className="mr-2 typo-sm-1">{t('showMore')}</div>
                    <PackIcon
                        id="arrow-left"
                        pack={IconsPack.SpriteIcons}
                        className="h-4 w-4 rotate-180 fill-primary-white"
                    />
                </div>
            </div>
            <div
                className={cn('grid grid-cols-5 grid-rows-2 gap-2', {
                    'grid-rows-1': oddsLength <= ROW_ODDS_COUNT,
                    '!flex': isSmall,
                })}
            >
                {isSmall && oddsLength > MIN_MOBILE_ODDS_IN_ROW ? (
                    <HorizontalScroll
                        containerClasses="flex"
                        glow="middle"
                        withScrollbar={false}
                    >
                        {oddsJsx}
                    </HorizontalScroll>
                ) : (
                    oddsJsx
                )}
            </div>
        </div>
    );
};

export default MultiCompetitorsMarket;
