import React from 'react';
import cn from 'classnames';
import type { PartialMatchMarket } from 'types';

import OddButton from 'components/betting/OddButton';
import { idPathParser } from 'utils';
import { ODD_STUB } from '../constants';

interface Props {
    market: PartialMatchMarket;
    withName?: boolean;
    sportEventId: string;
    isResizable?: boolean;
}

const MAX_COUNT_ODD_BUTTONS = 3 as const;

const OverviewMarket: React.FC<Props> = ({
    market,
    withName,
    sportEventId,
    isResizable,
}) => {
    const { name } = market;
    const odds = market?.odds || [ODD_STUB, ODD_STUB];
    const oddsList = odds.length === 1 ? [...odds, ODD_STUB] : odds;
    const isCountOfOdds = odds.length === MAX_COUNT_ODD_BUTTONS;

    const slicedOddsList = oddsList.slice(0, MAX_COUNT_ODD_BUTTONS);

    const marginBottomClass = isCountOfOdds
        ? cn('sm:not-last:mb-1')
        : cn('sm:not-last:mb-2');

    return (
        <div className="flex h-full w-full flex-col self-stretch">
            {withName && (
                <div className="hidden h-12 shrink-0 items-center sm:flex">
                    <div className="line-clamp-2 max-w-full overflow-hidden text-ellipsis font-normal text-primary-white typo-sm-1">
                        {name}
                    </div>
                </div>
            )}
            <div
                className={cn('flex h-full w-full pt-4', {
                    'sm:h-0 sm:grow sm:flex-col': isResizable,
                })}
            >
                {slicedOddsList.map((odd, index) => (
                    <OddButton
                        key={idPathParser.join('Odd', {
                            oddId: odd.id || `${index}`,
                            marketId: market.id,
                            sportEventId,
                        })}
                        odd={odd}
                        oddsCount={slicedOddsList.length}
                        classNames={cn(isResizable ? marginBottomClass : '')}
                        market={market}
                        isResizable={isResizable}
                        gap={2}
                    />
                ))}
            </div>
        </div>
    );
};

export default OverviewMarket;
