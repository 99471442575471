import { capitalize } from 'lodash';

import type {
    Hearthstone,
    Overwatch,
    StarCraft,
    StarCraft1,
} from 'types/gql.bet';
import { TeamHomeAway } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreType } from '../types';

const starCraftAdapter: ScoreboardAdapter<
    StarCraft1 | StarCraft | Overwatch | Hearthstone
> = {
    adaptOverviewScores(overview, { isMatchListView }) {
        const { maps } = overview;

        const scores = (maps || []).map((m) => ({
            title: `${m.number}`,
            home: getScore(m.winner, TeamHomeAway.Home),
            away: getScore(m.winner, TeamHomeAway.Away),
            type: ScoreType.Other,
        }));

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { currentMap: currentMapNumber, bestOf } = overview;

        const matchFormat = bestOf ? `BO${bestOf}:` : '';
        const matchPart = currentMapNumber
            ? `${capitalize(tSpecifier('map'))} ${currentMapNumber}`
            : '';
        const title = `${matchFormat} ${matchPart}`;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.Total,
        };
    },
};

export const getScore = (winner: TeamHomeAway, side: TeamHomeAway): string => {
    if (winner === TeamHomeAway.Unknown) {
        return '-';
    }

    return side === winner ? 'W' : 'L';
};

export default starCraftAdapter;
