import cn from 'classnames';
import { includes } from 'lodash';

import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import type { Pair } from 'types/api.bet';
import type { Competitor, CompetitorScore } from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import type { FixtureScoreboardAdapter, ScoreBoardScore } from '../types';
import { ScoreType } from '../types';
import { findAwayScore, getMessage } from './utils';

type HeaderByScoreType = {
    [key in ScoreTypes]?: string;
};

const { Time, TimerRunning, Pause, Var, Period } = SportEventMetaKey;

const {
    TYPE_YELLOW_CARD,
    TYPE_RED_CARD,
    TYPE_PERIOD_1ST_HALF,
    TYPE_PERIOD_2ND_HALF,
    TYPE_PERIOD_1ST_EXTRA,
    TYPE_PERIOD_2ND_EXTRA,
    TYPE_PERIOD_PENALTIES,
} = ScoreTypes;

const scoreType = [
    TYPE_PERIOD_1ST_HALF,
    TYPE_PERIOD_2ND_HALF,
    TYPE_PERIOD_1ST_EXTRA,
    TYPE_PERIOD_2ND_EXTRA,
    TYPE_PERIOD_PENALTIES,
];

const headerByScoreType: HeaderByScoreType = {
    [TYPE_PERIOD_1ST_HALF]: '1',
    [TYPE_PERIOD_2ND_HALF]: '2',
    [TYPE_PERIOD_1ST_EXTRA]: '1EH',
    [TYPE_PERIOD_2ND_EXTRA]: '2EH',
    [TYPE_PERIOD_PENALTIES]: 'P',
};

const scoresOrder: Record<string, number> = {
    [TYPE_PERIOD_1ST_HALF]: 0,
    [TYPE_PERIOD_2ND_HALF]: 1,
    [TYPE_PERIOD_1ST_EXTRA]: 2,
    [TYPE_PERIOD_2ND_EXTRA]: 3,
    [TYPE_PERIOD_PENALTIES]: 4,
};

const cardStyles = cn('h-3 w-[10px] rounded-default');

const titles = {
    [TYPE_YELLOW_CARD]: <div className={cn(cardStyles, 'bg-yellow-500')} />,
    [TYPE_RED_CARD]: <div className={cn(cardStyles, 'bg-red-900')} />,
} as const;

const footballFixtureAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta, competitors }, { translate }) {
        const currentMatchPart = getCurrentMatchPart(meta) as ScoreTypes;
        const time = Number(getMetaValueByName(meta, Time) || 0);
        const isTimerRunning = getMetaValueByName(meta, TimerRunning);
        const isVar = getMetaValueByName(meta, Var);
        const pause = getMetaValueByName(meta, Pause);

        const isPenaltiesPeriod = currentMatchPart === TYPE_PERIOD_PENALTIES;

        const penaltiesScore = isPenaltiesPeriod
            ? getTitlePenaltiesScore(competitors)
            : '';

        return {
            mainScoreType: ScoreType.Total,
            headerIcon: isVar
                ? {
                      icon: (
                          <PackIcon
                              id="var-icon"
                              pack={IconsPack.SpriteIcons}
                              size={SvgIconSize.WH_4}
                          />
                      ),
                  }
                : undefined,
            timer: !isPenaltiesPeriod
                ? {
                      isTimerRunning: !!isTimerRunning && !pause,
                      time,
                      isDecrement: false,
                  }
                : undefined,
            matchInfo: {
                title: getMessage(
                    currentMatchPart,
                    penaltiesScore,
                    translate,
                    !!pause
                ),
            },
        };
    },
    adaptFixtureScores({ competitors, meta }, { isMatchListView }) {
        const result: ScoreBoardScore[] = [];
        const [home, away] = competitors;

        const currentMatchPart = getCurrentMatchPart(meta) as ScoreTypes;

        const cardCount = {
            home: {
                [TYPE_YELLOW_CARD]: getCard(home.score, TYPE_YELLOW_CARD),
                [TYPE_RED_CARD]: getCard(home.score, TYPE_RED_CARD),
            },
            away: {
                [TYPE_YELLOW_CARD]: getCard(away.score, TYPE_YELLOW_CARD),
                [TYPE_RED_CARD]: getCard(away.score, TYPE_RED_CARD),
            },
        };

        const scoresList = home.score
            .reduce<ScoreBoardScore[]>((acc, period) => {
                if (includes(scoreType, period.type)) {
                    const score: ScoreBoardScore = {
                        title:
                            headerByScoreType[period.type as ScoreTypes] || '',
                        home: period.points.toString(),
                        away: findAwayScore(away, period.id).points,
                        type:
                            period.type === currentMatchPart
                                ? ScoreType.CurrentPart
                                : ScoreType.Other,
                    };

                    const orderIndex = scoresOrder[period.type];

                    acc[orderIndex] = score;

                    return acc;
                }

                return acc;
            }, [])
            .filter(Boolean);

        if (isMatchListView) {
            const lastTwoScores = scoresList.slice(-2);

            return cardCount.home[TYPE_RED_CARD] ||
                cardCount.away[TYPE_RED_CARD]
                ? [
                      {
                          home: cardCount.home[TYPE_RED_CARD].toString(),
                          away: cardCount.away[TYPE_RED_CARD].toString(),
                          title: titles[TYPE_RED_CARD],
                          type: ScoreType.ExtraInfo,
                      },
                      ...lastTwoScores,
                  ]
                : lastTwoScores;
        }

        ([TYPE_YELLOW_CARD, TYPE_RED_CARD] as const).forEach((key) => {
            const infoScore: ScoreBoardScore = {
                home: cardCount.home[key].toString(),
                away: cardCount.away[key].toString(),
                title: titles[key],
                type: ScoreType.ExtraInfo,
            };

            result.push(infoScore);
        });

        result.push(...scoresList);

        return result;
    },
};

export const getCurrentMatchPart = (
    meta: Array<Pair> | undefined
): string | undefined => {
    const period = 'period_';

    const currentMatchPart = getMetaValueByName(meta, Period);

    if (!currentMatchPart) return;

    return currentMatchPart.includes(period)
        ? currentMatchPart
        : `${period}${currentMatchPart}`;
};

const getCard = (score: CompetitorScore[], cardType: ScoreTypes): number => {
    return Number(score.find(({ type }) => type === cardType)?.points || 0);
};

const getTitlePenaltiesScore = (competitors: Competitor[]): string => {
    return competitors.reduce((score, competitor) => {
        const periodPenalties = competitor.score.find(
            ({ type }) => type === TYPE_PERIOD_PENALTIES
        );
        const points = periodPenalties?.points || 0;

        return `${score}${score.length > 0 ? ':' : ''}${points}`;
    }, '');
};

export default footballFixtureAdapter;
