import React, {
    FC,
    HTMLAttributes,
    memo,
    PropsWithChildren,
    ReactNode,
} from 'react';
import cn from 'classnames';

import useHorizontalScroll from './useHorizontalScroll';

import styles from './horizontalScroll.module.css';

interface Props extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    iconsClasses?: string | { right?: string; left?: string };
    containerClasses?: string;
    glow?: 'middle' | 'dark';
    withScrollbar?: boolean;
    sideSpacing?: number;
}

const buttonClasses = cn(
    'absolute top-0 z-10 hidden h-14 w-[64px] cursor-pointer items-center justify-center sm:flex'
);

const HorizontalScroll: FC<Props> = ({
    iconLeft,
    iconRight,
    className,
    children,
    iconsClasses = '',
    containerClasses = '',
    glow = 'middle',
    withScrollbar = true,
    sideSpacing,
    ...props
}) => {
    const {
        containerRef,
        showLeftArrow,
        showRightArrow,
        startScroll,
        stopScroll,
        updateArrowsVisibility,
    } = useHorizontalScroll({ sideSpacing });

    const isHiddenScrollbar =
        !withScrollbar || (!showLeftArrow && !showRightArrow);

    return (
        <div
            className={cn('relative w-full overflow-hidden', className, {
                [styles['glow-left']]: showLeftArrow && glow,
                [styles['glow-right']]: showRightArrow && glow,
                [styles['glow-dark']]: glow === 'dark',
            })}
            {...props}
        >
            {showLeftArrow && iconLeft && (
                <div
                    data-arrow="left"
                    className={cn(
                        'left-0 bg-gradient-to-r from-[#1c1c1c_0%] via-[#1c1c1c_15%] to-[rgba(28,28,28,0)_100%]',
                        buttonClasses,
                        typeof iconsClasses === 'string'
                            ? iconsClasses
                            : iconsClasses.left,
                        { hidden: !showLeftArrow }
                    )}
                    onMouseDown={startScroll}
                    onMouseUp={stopScroll}
                    onMouseLeave={stopScroll}
                >
                    {iconLeft}
                </div>
            )}
            {showRightArrow && iconRight && (
                <div
                    data-arrow="right"
                    className={cn(
                        'right-0 bg-gradient-to-r from-[rgba(20,23,29,0)_0%] via-[#1c1c1c_85%] to-[#1c1c1c_100%]',
                        buttonClasses,
                        typeof iconsClasses === 'string'
                            ? iconsClasses
                            : iconsClasses.right,
                        { hidden: !showRightArrow }
                    )}
                    onMouseDown={startScroll}
                    onMouseUp={stopScroll}
                    onMouseLeave={stopScroll}
                >
                    {iconRight}
                </div>
            )}
            <div
                ref={containerRef}
                onScroll={updateArrowsVisibility}
                className={cn(
                    'overflow-y-hidden overflow-x-scroll',
                    styles['horizontal-scroll'],
                    containerClasses,
                    {
                        [styles['horizontal-scroll-hidden']]: isHiddenScrollbar,
                        'sm:no-scrollbar': isHiddenScrollbar,
                        'pb-8':
                            withScrollbar && (showLeftArrow || showRightArrow),
                    }
                )}
            >
                {children}
            </div>
        </div>
    );
};

export default memo(HorizontalScroll);
