import { SportEventMetaKey } from 'app-constants';
import { TableTennis } from 'types/gql.bet';
import { getMetaValueByName } from 'utils';
import {
    FixtureScoreboardAdapter,
    ScoreboardAdapter,
    ScoreBoardScore,
    ScoreType,
} from '../types';
import { adaptFixtureScores, findAwayScore } from './utils';

const { Set } = SportEventMetaKey;

const tableTennisAdapter: ScoreboardAdapter<TableTennis> &
    FixtureScoreboardAdapter = {
    adaptFixtureScores({ competitors, meta }, { isMatchListView }) {
        const activeSet = getMetaValueByName(meta, Set);

        const scoreList = adaptFixtureScores(competitors, {
            scoreIteratee: (score, isZeroBased) => {
                const { id, number, points } = score;

                const isCurrentSet = Number(activeSet) === number;

                const awayPoints = Number(
                    findAwayScore(competitors[1], id).points
                );
                const homePoints = Number(points);

                const homeScore = getScoreByAdvantages({
                    firstScore: homePoints,
                    secondScore: awayPoints,
                    isCurrentSet,
                });

                const awayScore = getScoreByAdvantages({
                    firstScore: awayPoints,
                    secondScore: homePoints,
                    isCurrentSet,
                });

                return {
                    home: homeScore,
                    away: awayScore,
                    title: `${isZeroBased ? number + 1 : number}`,
                    type: isCurrentSet
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
                };
            },
        });

        if (isMatchListView) {
            return scoreList.slice(-3);
        }

        return scoreList;
    },
    adaptFixtureInfo({ meta }, { translate: { tSpecifier } }) {
        let title = '';

        const currentSet = getMetaValueByName(meta, Set);
        title = currentSet ? `${currentSet} ${tSpecifier('setnr')}` : '';

        return {
            mainScoreType: ScoreType.CurrentPart,
            matchInfo: {
                title,
            },
        };
    },
    adaptOverviewScores(
        { sets, currentSet, teams },
        { isMatchListView, translate: { tSpecifier } }
    ) {
        if (!sets?.length) return [];

        const scoreList: ScoreBoardScore[] = sets.map(
            ({ number, totalPointsHome, totalPointsAway }) => {
                const isCurrentSet = number === currentSet;

                const homeScore = getScoreByAdvantages({
                    firstScore: totalPointsHome,
                    secondScore: totalPointsAway,
                    isCurrentSet,
                });

                const awayScore = getScoreByAdvantages({
                    firstScore: totalPointsAway,
                    secondScore: totalPointsHome,
                    isCurrentSet,
                });

                return {
                    title: String(number),
                    home: homeScore,
                    away: awayScore,
                    type: isCurrentSet
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
                };
            }
        );

        const setsPoint = {
            title: tSpecifier('setnr'),
            home: `${teams.home.score}`,
            away: `${teams.away.score}`,
            type: ScoreType.ExtraInfo,
        };

        return isMatchListView
            ? scoreList.slice(-3)
            : [...scoreList, setsPoint];
    },
    adaptOverviewInfo({ currentSet, sets }, { translate: { tSpecifier } }) {
        const overviewInfo = {
            mainScoreType: ScoreType.CurrentPart,
            matchInfo: {
                title: '',
            },
        };

        if (!sets?.length) {
            return overviewInfo;
        }

        const translation = tSpecifier('setnr');

        overviewInfo.matchInfo.title =
            currentSet !== 0
                ? `${currentSet} ${translation}`
                : `${sets.length} ${translation}`;

        return overviewInfo;
    },
};

function getScoreByAdvantages({
    firstScore,
    secondScore,
    isCurrentSet,
}: {
    firstScore: number;
    secondScore: number;
    isCurrentSet: boolean;
}): string {
    return isCurrentSet
        ? getIsAdvantage(firstScore, secondScore)
        : String(firstScore);
}

function getIsAdvantage(first: number, secondScore: number): string {
    if (first < 10 || secondScore < 10) {
        return String(first);
    }

    const isWinner = Math.abs(first - secondScore) > 1;

    return first > secondScore && !isWinner ? 'A' : String(first);
}

export default tableTennisAdapter;
