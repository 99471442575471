import type { Mma } from 'types/gql.bet';
import { MmaFormat, MmaReason, MmaWinner } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreType } from '../types';

const FORMAT = {
    [MmaFormat.Three]: 3,
    [MmaFormat.Five]: 5,
};

type MatchTranslateKeys =
    IntlMessages['betting-sportEventPart']['sportEventPart'];

const WIN_REASON = {
    [MmaReason.D]: 'decision',
    [MmaReason.Dq]: 'disqualification',
    [MmaReason.Drw]: 'draw',
    [MmaReason.Ko]: 'knockout',
    [MmaReason.Sub]: 'submission',
    [MmaReason.Td]: 'technicalDecision',
    [MmaReason.Tko]: 'technicalKnockout',
    [MmaReason.U]: '',
};

const mmaAdapter: ScoreboardAdapter<Mma> = {
    adaptOverviewScores() {
        return [];
    },
    adaptOverviewInfo(
        overview,
        { translate: { tSpecifier, tSportEvent }, isMatchListView }
    ) {
        const { timer, format, round, result } = overview;
        const { reason, winner } = result;

        const matchFormat =
            format !== MmaFormat.Unknown
                ? `${tSpecifier('format')} ${FORMAT[format]}`
                : '';
        const matchRound = `${tSpecifier('roundnr')} ${round}`;
        const title = `${matchFormat} ${matchRound}`;

        const hasReason =
            winner !== MmaWinner.Draw &&
            winner !== MmaWinner.Unknown &&
            WIN_REASON[reason];

        const matchReason = WIN_REASON[reason] as keyof MatchTranslateKeys;

        const tMatchReason = tSportEvent
            ? tSportEvent(`sportEventPart.${matchReason}`)
            : '';

        const subTitle = hasReason ? tMatchReason : undefined;

        return {
            matchInfo: {
                title,
                subTitle: isMatchListView ? undefined : subTitle,
            },
            mainScoreType: hasReason ? ScoreType.Total : ScoreType.HiddenTotal,
            timer: hasReason
                ? undefined
                : {
                      time: timer.time,
                      isTimerRunning: timer.isActive,
                      isDecrement: true,
                  },
        };
    },
};

export function getWinner({
    side,
    result,
}: {
    side: MmaWinner;
    result: Mma['result'];
    withReason?: boolean;
}): string {
    const { winner } = result;

    switch (winner) {
        case side:
            return 'W';
        case MmaWinner.Draw:
            return 'D';
        case MmaWinner.Unknown:
            return '-';
        default:
            return 'L';
    }
}

export default mmaAdapter;
