import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';
import { adaptFixtureScores, findAwayScore } from './utils';

const { Set } = SportEventMetaKey;
const { TYPE_SET } = ScoreTypes;

const badmintonAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta }) {
        const currentSet = getMetaValueByName(meta, Set);
        const title = currentSet ? `${currentSet} S` : '';

        return {
            mainScoreType: ScoreType.Total,
            matchInfo: {
                title,
            },
        };
    },
    adaptFixtureScores({ competitors, meta }, { isMatchListView }) {
        const activeSet = getMetaValueByName(meta, Set);

        const scoreList = adaptFixtureScores(competitors, {
            scoreIteratee: (score) => {
                if (score.type === TYPE_SET) {
                    return {
                        home: score.points,
                        away: findAwayScore(competitors[1], score.id).points,
                        title: `${score.number}`,
                        type:
                            activeSet && +activeSet === score.number
                                ? ScoreType.CurrentPart
                                : ScoreType.Other,
                    };
                }
            },
        });

        if (isMatchListView) {
            return scoreList.slice(-2);
        }

        return scoreList;
    },
};

export default badmintonAdapter;
