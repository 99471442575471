import { MarketStatus, Odd, OddStatus } from 'types/api.bet';
import type { Market } from 'types/gql.bet';

export const ODD_STUB: Odd = {
    status: OddStatus.Cancelled,
    id: '',
    isActive: false,
    name: '',
    value: '',
    path: '',
    competitorId: '',
    competitorIds: [],
};

export const MARKET_STUB: Omit<Market, 'name'> = {
    id: '',
    priority: 0,
    status: MarketStatus.Cancelled,
    odds: [ODD_STUB, ODD_STUB],
    specifiers: [],
    typeId: -999,
    tags: [],
    path: '',
};

export const MOBILE_OVERVIEW_MARKETS_TYPE_ID: number[] = [1, 20, 252, 302];
