export enum PlayerType {
    Twitch = 'Twitch',
    Youtube = 'Youtube',
    Grid = 'Grid',
    Iframe = 'Iframe',
}

export const localesCmsTrading: Record<string, string> = {
    cn: 'zh',
    'en-ca': 'en_CA',
    'fr-ca': 'fr_CA',
    'tl-ph': 'tl',
    'uk-ua': 'uk',
};

export const betRadarLocales: Record<string, string> = {
    ...localesCmsTrading,
    'uk-ua': 'ukr',
};

export const MATCH_TRACKER_CONTAINER = '#matchTracker' as const;

export const WIDGET_LOADER =
    '//widgets.sir.sportradar.com/e6ed44220c02382e5ef47cc6b8391a86/widgetloader' as const;
