import { Stream } from 'types/gql.bet';
import {
    betRadarLocales,
    localesCmsTrading,
    MATCH_TRACKER_CONTAINER,
    PlayerType,
    WIDGET_LOADER,
} from './constants';

export function getPlayerType(url: string): PlayerType {
    const currentUrl = url.toLocaleLowerCase();

    switch (true) {
        case currentUrl.includes('youtube'): {
            return PlayerType.Youtube;
        }

        case currentUrl.includes('twitch'): {
            return PlayerType.Twitch;
        }

        default: {
            return PlayerType.Iframe;
        }
    }
}

export function getYouTubeVideoIdFromUrl(url: string): string | undefined {
    const splittedUrl = url.split('/');

    return splittedUrl[splittedUrl.length - 1];
}

export function findStream(
    streams: Stream[],
    activeLocale: string
): Stream | undefined {
    if (streams.length === 0) {
        return;
    }

    if (streams.length === 1) {
        return streams[0];
    }

    const convertedCmsLocale = localesCmsTrading[activeLocale] || activeLocale;

    const currentStream = streams.find((stream) => {
        return stream.locale.includes(convertedCmsLocale);
    });

    if (!currentStream) return streams.find(({ locale }) => locale === 'en');

    return currentStream;
}

export const loadMatchTracker = (activeLocale: string): HTMLScriptElement => {
    const locale = betRadarLocales[activeLocale] || activeLocale;

    const script = document.createElement('script');
    script.setAttribute('src', WIDGET_LOADER);
    script.setAttribute('n', 'SIR');
    script.setAttribute('data-sr-language', locale);
    script.async = true;

    document.head.appendChild(script);

    return script;
};

export const initMatchTracker = ({
    betradarId,
    layout,
    hasScoreboard,
}: {
    betradarId: string | null;
    layout: 'single' | 'double';
    hasScoreboard: boolean;
}): void => {
    if (!window.SIR || !betradarId) return;

    window.SIR('addWidget', MATCH_TRACKER_CONTAINER, 'match.lmtPlus', {
        pitchBgImage: '',
        showOdds: false,
        layout,
        scoreboard: hasScoreboard ? 'compact' : 'disable',
        momentum: hasScoreboard ? 'compact' : 'disable',
        matchId: betradarId,
    });
};
