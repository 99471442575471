import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { debounce } from 'lodash';
import Observable from 'zen-observable';

import { isSsr } from 'utils/isSsr';

interface Output {
    isSubscribed: boolean;
    inViewRef: (node?: Element | null | undefined) => void;
}

let onScroll$: Observable<any> | undefined;

if (!isSsr()) {
    onScroll$ = new Observable((observer) => {
        window.addEventListener('scroll', (event) => {
            observer.next(event);
        });
    });
}

const useSportEventInViewSubscription = (): Output => {
    const [inViewRef, isInView] = useInView();
    const [isSubscribed, setSubscribed] = useState<boolean>(false);

    const isScrolling = useRef<boolean>(false);
    const inView = useRef<boolean>(isInView);
    inView.current = isInView;

    const handleScroll = useMemo(
        () =>
            debounce(() => {
                setSubscribed(inView.current);
                isScrolling.current = false;
            }, 350),
        []
    );

    useEffect(() => {
        if (onScroll$) {
            const subscription = onScroll$.subscribe(() => {
                handleScroll();
                isScrolling.current = true;
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [handleScroll]);

    useEffect(() => {
        if (isScrolling.current) return;

        setSubscribed(isInView);
    }, [isInView]);

    return {
        isSubscribed,
        inViewRef,
    };
};

export default useSportEventInViewSubscription;
