import cn from 'classnames';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import { Futsal, FutsalPeriod, PenaltyStatus } from 'types/gql.bet';
import {
    Flatten,
    ScoreboardAdapter,
    ScoreBoardScore,
    ScoreType,
} from '../types';
import { getMessage } from './utils';

type Periods<T extends { periods?: Futsal['periods'] }> = NonNullable<
    Flatten<T['periods']>
>;

type Penalties<
    T extends {
        home: {
            penalties?: Array<{
                number: number;
                status?: PenaltyStatus;
            }> | null;
        };
    },
> = Flatten<T['home']['penalties']>;

type FutsalHeaderByScoreType = {
    [key in FutsalPeriod]?: string;
};

const futsalHeaderByScoreType: FutsalHeaderByScoreType = {
    [FutsalPeriod.FirstHalf]: '1',
    [FutsalPeriod.SecondHalf]: '2',
    [FutsalPeriod.FirstExtra]: '1EH',
    [FutsalPeriod.SecondExtra]: '2EH',
    [FutsalPeriod.Penalties]: 'P',
};

const futsalAdapter: ScoreboardAdapter<Futsal> = {
    adaptOverviewInfo(overview, { translate }) {
        const { timer, periods, currentPeriod, isPenaltyTime } = overview;

        let penaltiesScore = '';

        if (currentPeriod === FutsalPeriod.Penalties) {
            const penaltiesPeriod = periods?.find(
                ({ period }) => period === FutsalPeriod.Penalties
            );

            const afterMatchPenaltiesHome = penaltiesPeriod?.home.score || 0;
            const afterMatchPenaltiesAway = penaltiesPeriod?.away.score || 0;

            penaltiesScore = `${afterMatchPenaltiesHome}:${afterMatchPenaltiesAway}`;
        }

        return {
            mainScoreType: ScoreType.Total,
            timer:
                currentPeriod !== FutsalPeriod.Penalties && !isPenaltyTime
                    ? {
                          isTimerRunning: timer.isActive,
                          time: timer.time,
                          isDecrement: false,
                      }
                    : undefined,
            matchInfo: {
                title: getMessage(currentPeriod, penaltiesScore, translate),
            },
        };
    },
    adaptOverviewScores(overview, { isMatchListView }) {
        const { teams, currentPeriod, periods } = overview;
        const result: ScoreBoardScore[] = [];
        const iconStyles = cn('fill-primary-white');
        const cardStyles = cn('h-3 w-[10px] rounded-default');
        const titles = {
            penalties: (
                <PackIcon
                    id="penalty"
                    pack={IconsPack.SpriteIcons}
                    className={iconStyles}
                    size={SvgIconSize.WH_4}
                />
            ),
            yellowCards: <div className={cn(cardStyles, 'bg-yellow-500')} />,
            redCards: <div className={cn(cardStyles, 'bg-red-900')} />,
        } as const;

        const penaltiesCount = {
            home: 0,
            away: 0,
        };

        if (!periods) return result;

        const scoresList = periods.map((item) => {
            const { period } = item;

            if (period !== FutsalPeriod.Penalties) {
                (['home', 'away'] as const).forEach((side) => {
                    penaltiesCount[side] += getPeriodPenaltiesCount(
                        item[side].penalties || []
                    );
                });
            }

            const score: ScoreBoardScore = {
                title: futsalHeaderByScoreType[period] || '',
                home: item.home.score.toString(),
                away: item.away.score.toString(),
                type:
                    period === currentPeriod
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            };

            return score;
        });

        if (isMatchListView) {
            const lastTwoScores = scoresList.slice(-2);

            return teams.home.redCards || teams.away.redCards
                ? [
                      {
                          home: teams.home.redCards.toString(),
                          away: teams.away.redCards.toString(),
                          title: titles.redCards,
                          type: ScoreType.ExtraInfo,
                      },
                      ...lastTwoScores,
                  ]
                : lastTwoScores;
        }

        (['yellowCards', 'redCards'] as const).forEach((key) => {
            const infoScore: ScoreBoardScore = {
                home: teams.home[key].toString(),
                away: teams.away[key].toString(),
                title: titles[key],
                type: ScoreType.ExtraInfo,
            };

            result.push(infoScore);
        });

        result.push(...scoresList);

        result.unshift({
            home: penaltiesCount.home.toString(),
            away: penaltiesCount.away.toString(),
            type: ScoreType.ExtraInfo,
            title: titles.penalties,
        });

        return result;
    },
};

function getPeriodPenaltiesCount(
    penalties: Array<Penalties<Periods<Futsal>>>
): number {
    return penalties.filter((penalty) => {
        const status = penalty?.status;

        return status === PenaltyStatus.Ended;
    }).length;
}

export default futsalAdapter;
