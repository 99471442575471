import type { Basketball, EBasketball } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreBoardScore, ScoreType } from '../types';

const QUARTERS_COUNT = 4 as const;

const basketballAdapter: ScoreboardAdapter<Basketball | EBasketball> = {
    adaptOverviewInfo(
        overview,
        { isMatchListView, translate: { tSpecifier } }
    ) {
        const { currentQuarter, quarterFormat, pause, timer } = overview;

        const currentQuarterTitle = isMatchListView
            ? `${currentQuarter} Q`
            : `Q${currentQuarter}.${quarterFormat}m`;

        // TODO: check if we need to add number of overtime from meta `overtime 5`
        const overtimeOrQuarterTitle =
            currentQuarter <= QUARTERS_COUNT
                ? currentQuarterTitle
                : tSpecifier('overtimenr');

        const title = pause ? tSpecifier('timeout') : overtimeOrQuarterTitle;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.Total,
            timer: {
                isTimerRunning: timer.isActive && !pause,
                time: timer.time,
                isDecrement: true,
            },
        };
    },
    adaptOverviewScores(overview, options) {
        const { teams, quarters, currentQuarter } = overview;
        const result: ScoreBoardScore[] = [];

        const isMatchListView = options?.isMatchListView;

        const { home, away } = teams;

        if (!quarters) return result;

        quarters.forEach((quarter) => {
            const isOvertime = quarter.number > QUARTERS_COUNT;

            if (isOvertime && quarter.number !== quarters.length) return;

            const scoreType =
                quarter.number === currentQuarter
                    ? ScoreType.CurrentPart
                    : ScoreType.Other;

            const score: ScoreBoardScore = {
                title: isOvertime
                    ? `${quarter.number - QUARTERS_COUNT}OT`
                    : `${quarter.number}`,
                home: String(quarter.home.score),
                away: String(quarter.away.score),
                type: isOvertime ? ScoreType.ExtraInfo : scoreType,
            };

            result.push(score);
        });

        if (isMatchListView) {
            return result
                .filter((score) => score.type !== ScoreType.ExtraInfo)
                .slice(-2);
        }

        if (home.fouls || away.fouls) {
            result.unshift({
                title: `F`,
                home: String(home.fouls),
                away: String(away.fouls),
                type: ScoreType.ExtraInfo,
            });
        }

        return result;
    },
};

export default basketballAdapter;
