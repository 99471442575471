import { ScoreTypes, SportEventMetaKey } from 'app-constants';
import { getMetaValueByName } from 'utils';
import { FixtureScoreboardAdapter, ScoreType } from '../types';
import { adaptFixtureScores, findAwayScore, makeMetaFinder } from './utils';

const findGameState = makeMetaFinder(
    SportEventMetaKey.GameState,
    SportEventMetaKey.StateNumber
);

const { TYPE_OVERTIME } = ScoreTypes;

const rocketLeagueAdapter: FixtureScoreboardAdapter = {
    adaptFixtureInfo({ meta }, { translate: { tSpecifier } }) {
        const currentMeta = findGameState(meta);

        if (!currentMeta) {
            return { matchInfo: { title: '' }, mainScoreType: ScoreType.Total };
        }

        const time = getMetaValueByName(meta, SportEventMetaKey.Time) || 0;

        const isTimerRunning =
            getMetaValueByName(meta, SportEventMetaKey.TimerRunning) === '1';

        const isOvertime = currentMeta.name === TYPE_OVERTIME;

        return {
            matchInfo: {
                title: isOvertime
                    ? tSpecifier('overtimenr')
                    : `$${tSpecifier('map')} ${currentMeta.value}`,
            },
            timer: {
                isDecrement: true,
                isTimerRunning,
                time: +time,
            },
            mainScoreType: ScoreType.Total,
        };
    },
    adaptFixtureScores({ competitors }, { isMatchListView }) {
        const scoreList = adaptFixtureScores(competitors, {
            scoreIteratee: (score) => {
                if (score.type === ScoreTypes.TYPE_ROUND_OVERTIME) {
                    return {
                        home: score.points,
                        away: findAwayScore(competitors[1], score.id).points,
                        title: `OT`,
                        type: ScoreType.ExtraInfo,
                    };
                }
            },
        });

        if (isMatchListView) {
            return scoreList
                .filter((score) => score.type !== ScoreType.ExtraInfo)
                .slice(-2);
        }

        return scoreList;
    },
};

export default rocketLeagueAdapter;
