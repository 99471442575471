import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { useGetDeeplinkMatchData } from 'components/DeepLinkMatchData';
import DebugInfo, { isDebugEnabled } from '../../DebugInfo';
import usePinedSportEvent from '../../PinedSportEventList/usePinedSportEvent';

interface SubscriptionRowDebugInfoProps {
    sportEventId: string;
    isSubscribed: boolean;
}

const SubscriptionRowDebugInfo = ({
    sportEventId,
    isSubscribed,
}: SubscriptionRowDebugInfoProps) => {
    const [isEnable, setIsEnable] = useState<boolean>(false);

    const { isPinnedSportEventsEnabled, pinedSportEventIds } =
        usePinedSportEvent();

    const { deeplinkMatchData, isDeeplinkMatchEnabled } =
        useGetDeeplinkMatchData();

    const { matchId: deepMatchId } = deeplinkMatchData || {};

    useEffect(() => {
        setIsEnable(isDebugEnabled());
    }, []);

    if (!isEnable) return null;

    const isDeeplinkMatch =
        isDeeplinkMatchEnabled && deepMatchId === sportEventId;

    const isPined =
        isPinnedSportEventsEnabled &&
        !isDeeplinkMatch &&
        pinedSportEventIds?.includes(sportEventId);

    return (
        <div className="absolute left-auto right-0 z-[999] flex p-2 text-grey-500 typo-sm-1">
            <DebugInfo text={sportEventId} />
            <div className="absolute right-0 top-4 flex space-x-1 pr-2">
                <div
                    className={cn('text-primary-orange-toxic', {
                        'text-green-700': isSubscribed,
                    })}
                >
                    {isSubscribed ? 'Subscribed' : 'Unsubscribed'}
                </div>

                {isPined && <div className="text-turquoise-700">Pined</div>}
                {isDeeplinkMatch && (
                    <div className="text-turquoise-700">DeepMatchId</div>
                )}
            </div>
        </div>
    );
};

export default React.memo(SubscriptionRowDebugInfo);
