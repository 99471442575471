import React, { FC, memo } from 'react';
import cn from 'classnames';

import SmartLink from 'components/SmartLink';
import SportIcon from 'components/SportIcon';
import { AnalyticAttrs } from 'services/GoogleAnalytics';
import { OriginType } from '../SportEventRow/constants';

interface Props {
    name?: string;
    sportId: string;
    isResizable: boolean;
    analytic: Partial<AnalyticAttrs>;
    headerLinkHref?: string;
    origin: OriginType;
}

const SportEventsHeader: FC<Props> = ({
    name,
    sportId,
    isResizable,
    headerLinkHref,
    analytic,
    origin,
}) => {
    const isCarouselOrigin = origin === OriginType.Carousel;

    const content = (
        <div
            className={cn(
                'flex h-10 items-center gap-2 bg-surface-middle px-4',
                { 'my-2': isResizable }
            )}
            data-test="tournament-header"
        >
            <SportIcon sportId={sportId} />
            <div
                className="truncate text-primary-white typo-sm-1"
                data-test="tournament-name"
            >
                {name}
            </div>
        </div>
    );

    return (
        <div
            className={cn(
                isResizable ? 'mb-2' : 'border-b-default border-surface-dark',
                { 'mb-1 ': isCarouselOrigin && !isResizable }
            )}
        >
            {headerLinkHref ? (
                <SmartLink
                    href={headerLinkHref}
                    analyticsAttrs={{
                        'data-category': analytic['data-category'],
                        'data-action': analytic['data-action'],
                    }}
                >
                    {content}
                </SmartLink>
            ) : (
                content
            )}
        </div>
    );
};

export default memo(SportEventsHeader);
