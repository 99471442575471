import { useReactiveVar } from '@apollo/client';

import { useRouter } from 'components/HybridRouter';
import { useRouterQuery } from 'hooks';
import { useGsspData } from 'layouts/Root/PagePropsContext';
import { AppLink } from 'types/applink';
import { isSsr } from 'utils/isSsr';
import pinedSportEventIdsVar from './pinedSportEventIdsVar';

interface Output {
    pinedSportEventIds: string[];
    isPinnedSportEventsEnabled: boolean;
}

const usePinedSportEvent = (): Output => {
    const { pathname } = useRouter();
    const { tournamentId = '' } = useRouterQuery();
    const pinedSportEventIdsClient = useReactiveVar(pinedSportEventIdsVar);
    const { pinnedSportEvents: pinnedSportEventsSsr } = useGsspData();

    const pinedSportEventIdsSsr =
        pinnedSportEventsSsr?.matches.sportEvents.map(({ id }) => id) || [];

    const pinedSportEventIds = isSsr()
        ? pinedSportEventIdsSsr
        : pinedSportEventIdsClient;

    const isPathForPinnedEvents =
        pathname === AppLink.BETS ||
        pathname === AppLink.NEXTTOGO ||
        pathname === AppLink.LIVE;

    const isPinnedSportEventsEnabled = !tournamentId && isPathForPinnedEvents;

    return {
        pinedSportEventIds: tournamentId ? [] : pinedSportEventIds,
        isPinnedSportEventsEnabled,
    };
};

export default usePinedSportEvent;
