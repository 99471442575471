import { map } from 'lodash';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import type { Lol } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreBoardScore, ScoreType } from '../types';

const lolAdapter: ScoreboardAdapter<Lol> = {
    adaptOverviewScores(overview, { isMatchListView }) {
        const {
            currentMap: currentMapNumber,
            maps,
            currentGoldLead,
        } = overview;

        const scores: ScoreBoardScore[] = map(maps, (m) => {
            return {
                title: `${m.number}`,
                home: `${m.teams.home.kills}`,
                away: `${m.teams.away.kills}`,
                type:
                    m.number === currentMapNumber
                        ? ScoreType.CurrentPart
                        : ScoreType.Other,
            };
        });

        if (isMatchListView) {
            return scores.slice(-2);
        }

        if (currentGoldLead) {
            scores.unshift({
                title: (
                    <PackIcon
                        id="gold"
                        pack={IconsPack.SpriteIcons}
                        size={SvgIconSize.WH_4}
                        className="fill-grey-500"
                    />
                ),
                home: `${currentGoldLead / 1000}k`,
                away: `${currentGoldLead / -1000}k`,
                type: ScoreType.ExtraInfo,
            });
        }

        return scores;
    },
    adaptOverviewInfo(overview, { translate: { tSpecifier } }) {
        const { currentMap: currentMapNumber, timer, bestOf } = overview;

        const title = `${bestOf ? `BO${bestOf}: ` : ''}${tSpecifier(
            'map'
        )} ${currentMapNumber}`;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.CurrentPart,
            // TODO: update with the latest version from current gg
            timer: {
                time: timer.time,
                isTimerRunning: timer.isActive,
                isDecrement: false,
            },
            bo: bestOf,
        };
    },
};

export default lolAdapter;
