import { FC, memo } from 'react';
import type { PartialMatch, PartialMatchMarkets } from 'types';

import SportEventSeoMicromarking from 'components/SportEventSeoMicromarking';
import { Action, useAnalyticAttrs } from 'services/GoogleAnalytics';
import { Stream } from 'types/api.bet';
import SportEventsHeader from '../SportEventsHeader';
import { HeaderType, OriginType } from './constants';
import SportEventInViewSubscription from './SportEventInViewSubscription';
import SportEventProvider from './SportEventProvider';
import SportEventRowBody from './SportEventRowBody';
import useSportEventRow from './useSportEventRow';

interface Props {
    sportEventId: string;
    fixture: PartialMatch['fixture'];
    streams?: Stream[];
    markets: PartialMatchMarkets;
    slug: string;
    meta: PartialMatch['meta'];
    version: string;
    isResizable?: boolean;
    headerType?: HeaderType;
    origin?: OriginType;
}

const SportEventRow: FC<Props> = ({
    sportEventId,
    fixture,
    streams,
    markets,
    slug,
    meta,
    version,
    isResizable = true,
    headerType = HeaderType.None,
    origin = OriginType.Default,
}) => {
    const {
        isPrematchView,
        matchRoute,
        matchDay,
        matchTime,
        isAvailableStream,
        headerName,
        headerLinkHref,
    } = useSportEventRow({
        fixture,
        streams,
        matchSlug: slug,
        meta,
        headerType,
    });

    const analytic = useAnalyticAttrs({
        defaultAttrs: {
            'data-action': Action.ClickOnLink,
        },
    });

    return (
        <SportEventProvider sportEventId={sportEventId}>
            <SportEventInViewSubscription
                sportId={fixture.sportId}
                sportEventId={sportEventId}
                version={version}
            >
                {(overview) => (
                    <>
                        {headerType !== HeaderType.None && (
                            <SportEventsHeader
                                name={headerName}
                                sportId={fixture.sportId}
                                isResizable={isResizable}
                                analytic={analytic}
                                headerLinkHref={headerLinkHref}
                                origin={origin}
                            />
                        )}
                        <SportEventRowBody
                            origin={origin}
                            matchRoute={matchRoute}
                            fixture={fixture}
                            matchDay={matchDay}
                            matchTime={matchTime}
                            sportEventId={sportEventId}
                            isResizable={isResizable}
                            isAvailableStream={isAvailableStream}
                            markets={markets}
                            overview={overview}
                            isPrematchView={isPrematchView}
                            withTournamentHeader={
                                headerType === HeaderType.Tournament
                            }
                            meta={meta}
                        />
                    </>
                )}
            </SportEventInViewSubscription>
            <SportEventSeoMicromarking fixture={fixture} />
        </SportEventProvider>
    );
};

export default memo(SportEventRow);
