import { Cricket, CricketGameFormat, TeamHomeAway } from 'types/gql.bet';
import { ScoreboardAdapter, ScoreBoardScore, ScoreType } from '../types';

export type SportEventPartTranslateKeys =
    IntlMessages['betting-sportEventPart']['sportEventPart'];

export const GAME_FORMAT = {
    [CricketGameFormat.InningAndOneDayInternational]: 'international',
    [CricketGameFormat.TestMatch]: 'test',
    [CricketGameFormat.Twenty]: 'twenty',
    [CricketGameFormat.Unknown]: '',
};

const cricketAdapter: ScoreboardAdapter<Cricket> = {
    adaptOverviewInfo(
        overview,
        { translate: { tSportEvent, tSpecifier }, isMatchListView }
    ) {
        const { currentInning, gameFormat } = overview;

        const format = GAME_FORMAT[
            gameFormat
        ] as keyof SportEventPartTranslateKeys;

        const inningText = `${currentInning} ${tSpecifier('inningnr')}`;

        const title =
            isMatchListView || gameFormat === CricketGameFormat.Unknown
                ? inningText
                : `${inningText} ${
                      tSportEvent ? tSportEvent(`sportEventPart.${format}`) : ''
                  }`;

        return {
            matchInfo: { title },
            mainScoreType: ScoreType.Total,
        };
    },
    adaptOverviewScores(overview, { isMatchListView }) {
        const { innings, currentInning } = overview;

        if (!innings?.length) return [];

        const scores: ScoreBoardScore[] = innings.map(
            ({ number, halfNumber, points, team }) => {
                return {
                    title: `${number}:${halfNumber}`,
                    home: `${homeAwayScore(points, team, TeamHomeAway.Home)}`,
                    away: `${homeAwayScore(points, team, TeamHomeAway.Away)}`,
                    type:
                        number === currentInning
                            ? ScoreType.CurrentPart
                            : ScoreType.Other,
                };
            }
        );

        if (isMatchListView) {
            return scores.slice(-2);
        }

        return scores;
    },
};

export function homeAwayScore(
    points: number,
    teamOverview: TeamHomeAway,
    team: TeamHomeAway
): string | number {
    if (teamOverview === TeamHomeAway.Unknown) return '-';

    return teamOverview === team ? points : 0;
}

export default cricketAdapter;
